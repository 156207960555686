import { ReactNode, useMemo, useState } from 'react';
import arrayDiff from 'arr-diff';
import { cloneDeep, isFunction, omit, uniq } from 'lodash';
import { format as formatDate } from 'date-fns';
import {
    DodWizardContext,
    DodWizardContextValue,
} from '@/components/DodConfigEditor/DodRunConfigWizard/DodWizardContext';
import { RowColConfig } from '@/components/DodConfigEditor/types';
import { defaultProductOrder, factSetToDisplayNames } from '@/components/DodConfigEditor/common/utils';
import { toAxisDef } from '@/components/DodConfigEditor/toAxisDef';
import { useApp } from '@/contexts/UserContext';
import { TimePeriodRange } from '@/utils/timePeriod/TimePeriodRange';
import { caseInsensitiveReverseSort, caseInsensitiveSort } from '@/utils/Sort';
import {
    DodColDef,
    DodFilter,
    DodFilters,
    DodProductSubDimension,
    DodRowDef,
    DodRunConfig,
    SortType,
} from '@/types/DodRun';

export interface DodWizardProviderProps {
    children: ReactNode | ReactNode[];
}

export function DodWizardProvider({children}: DodWizardProviderProps) {
    const { maxDataDates } = useApp();

    const maxDate = useMemo<string>(() => {
        return formatDate(maxDataDates.rms!, 'yyyyMMdd');
    }, [maxDataDates]);

    const [value, setValue] = useState<DodWizardContextValue>({
        dataPointCount: 0,
        setDataPointCount(dataPointCount: number): void {
            setValue(prevValue => ({
                ...prevValue,
                dataPointCount
            }))
        },
        calculatingDataPoints: false,
        setCalculatingDataPoints(calculatingDataPoints: boolean): void {
            setValue(prevValue => ({
                ...prevValue,
                calculatingDataPoints
            }))
        },
        runType: 'subscription',
        runConfig: cloneDeep(DEFAULT_RUN_CONFIG),
        applyValues(values: ((prevState: DodWizardContextValue) => DodWizardContextValue) | Partial<DodWizardContextValue>): void {
            setValue(prevValue => {
                if (isFunction(values)) {
                    return values(prevValue);
                }

                return {
                    ...prevValue,
                    ...values,
                }
            })
        },
        setRunConfig(value: ((prevState: DodRunConfig) => DodRunConfig) | DodRunConfig): void {
            setValue(prevState => ({
                ...prevState,
                runConfig: isFunction(value) ? value(prevState.runConfig) : value
            }));
        },
        rowColConfigs: cloneDeep(DEFAULT_ROW_COL_CONFIGS),
        updateRowColConfigs() {
            setValue(value => {

                const {rowColConfigs, runConfig} = value;
                const {filters, layout} = runConfig;
                const configs = rowColConfigs;
                const currentFilters: DodFilters = filters;
                const categoryConfig: RowColConfig | undefined = configs.find(config => config.dim === 'categories');
                const axis = categoryConfig?.axis ?? 'row';
                const isQuickLayoutApplied: boolean = runConfig.layout.quickLayoutCode !== 'cl' && runConfig.layout.quickLayoutCode !== undefined;

                // get the filters that are already in the rowColConfigs (we don't want to move these because they may have
                // already been ordered, we'll use these later to decide what needs to added and removed
                const previousProductDims: string[] = configs.map(v => v.dim).filter(dim => !excludedDims.includes(dim));

                // get the new product filters, excluding characteristics and custom characteristic (because we'll
                // handle them next) and markets and time periods (because they can't be changed in this step)
                let currentProductDims: (keyof DodFilters | string)[] = Object.entries(currentFilters)
                    .filter(([key, value]) => {
                        const filter = value as DodFilter;
                        return !allCharKeys.includes(key as any) && !excludedDims.includes(key) &&
                            (filter.values === 'all' || filter.values.length || filter.summedSelections.length)
                    })
                    .map(([key]) => key)
                    .sort((a, b) => {
                        return defaultProductOrder.indexOf(a as DodProductSubDimension) - defaultProductOrder.indexOf(b as DodProductSubDimension)
                    });

                // ensure we always have product descriptions if we have upcs
                if (currentProductDims.includes('upcs') && !currentProductDims.includes('productDescriptions')) {
                    const upcsIndex = currentProductDims.indexOf('upcs');
                    currentProductDims.splice(upcsIndex + 1, 0, 'productDescriptions');
                }

                // In order to alphabetically order values in chars and custom chars while retaining
                // group selections of char and cust char, performing separate sorts and merging as below
                const characteristics = Object.values({
                    ...currentFilters.characteristics
                }).map(value => value.displayName).sort(caseInsensitiveSort);

                const customCharacteristics = Object.values({
                    ...currentFilters.customCharacteristics
                }).map(value => value.displayName).sort(caseInsensitiveSort);

                const ppgs = Object.values({
                    ...currentFilters.ppgs
                }).map(value => value.displayName).sort(caseInsensitiveSort);

                // find any characteristic filters that are in use
                const currentCharacteristicsAndPpgs = [...characteristics, ...customCharacteristics, ...ppgs]

                // add the characteristics and custom characteristics to the list
                currentProductDims.push(...currentCharacteristicsAndPpgs);

                // figure out what needs to be added and removed.
                const addedProductDimensions: string[] = arrayDiff(currentProductDims, previousProductDims);
                const removedProductDimensions: string[] = arrayDiff(previousProductDims, currentProductDims);

                // figure where to insert any new values. this could be skipped if there are no new values, but that's
                // over-optimized and unnecessary.  If there are no products this will produce -1 which inserts at the end
                // @ts-ignore
                const lastProductIndex: number = configs.findLastIndex(v => v.type === 'products') + 1;
                // replace the new values to the existing configs
                configs.splice(lastProductIndex, 0, ...addedProductDimensions.map<RowColConfig>(dim => ({
                    axis,
                    type: 'products',
                    dim,
                    sortType: 'manual',
                    values: [],
                    hide: false,
                    pageBy: false,
                    stack: false,
                })))

                // return a new array by filtering out any removed values.  Even if no values are removed this produces
                // a new array so react can be "react" accordingly
                const updatedConfigs = configs.filter(config => config.type !== 'products' || !removedProductDimensions.includes(config.dim));

                // find previous and current market names
                const marketConfig = configs.find(config => config.type === 'markets')!;
                // get sort applied for markets
                const marketSort: SortType | "default" = configs.find(config => config.type === 'markets')!.sortType;
                const previousMarketNames: string[] = marketConfig.values;
                // get the names of the markets and summed market selections
                const markets = filters.markets;
                const currentMarketNames: string[] = [
                    ...markets.values.map(market => market.name),
                    ...markets.summedSelections.map(ss => ss.name)
                ].sort(caseInsensitiveSort);
                // figure out which markets need to be added and removed.
                const addedMarketNames: string[] = arrayDiff(currentMarketNames, previousMarketNames);
                const removedMarketNames: string[] = arrayDiff(previousMarketNames, currentMarketNames);
                let sortedMarkets: string[] = [];
                const { values: marketValues, summedSelections: marketSummedSelections } = runConfig.filters.markets;
                if (marketSort === 'asc') {
                    // `marketSort === 'asc'` => BYZ-9320 maintain asc sort for markets if 'asc' sort selected
                    sortedMarkets = marketValues.map((market) => market.name).sort(caseInsensitiveSort);
                    marketConfig.values = [...sortedMarkets, ...marketSummedSelections.map((ss) => ss.name)];
                } else if (
                    marketSort === 'desc' ||
                    (marketSort === 'manual' && isQuickLayoutApplied)
                ) {
                    // `marketSort === 'desc'` => BYZ-9320 maintain desc sort for markets if 'desc' sort selected
                    // `(marketSort === 'manual' && isQuickLayoutApplied)` => BYZ-9320 to maintain default asc order everytime quicklayout is applied in manual mode (this may happen in rerun)

                    sortedMarkets = marketValues.map((market) => market.name).sort(caseInsensitiveReverseSort);
                    marketConfig.values = [...sortedMarkets, ...marketSummedSelections.map((ss) => ss.name)];
                } else {
                    // BYZ-9320 append markets to end if no sort is selected (means 'default' or 'manual' sort is applied)
                    // remove and add markets
                    marketConfig.values = [
                        ...marketConfig.values.filter((market) => !removedMarketNames.includes(market)),
                        ...addedMarketNames,
                    ];
                }


                // find previous and current time periods
                const timePeriodConfig: RowColConfig = configs.find(config => config.type === 'time_periods')!
                // get sort applied for timeperiods
                const timePeriodSort: SortType | "default" = configs.find(config => config.type === 'time_periods')!.sortType;
                const previousTimePeriods: string[] = timePeriodConfig.values;
                const timePeriodFilters = currentFilters.timePeriods;
                // we need to flatten this array to account for custom time periods which are stored as nested arrays
                const orderedTimePeriods = timePeriodFilters.values.flat()
                    .map(tp => new TimePeriodRange(tp, maxDate))
                    .sort(TimePeriodRange.compareDesc)
                    .map(tp => tp.toLegacyDodString())
                const currentTimePeriods: string[] = [
                    ...orderedTimePeriods,
                    ...timePeriodFilters.summedSelections.map(ss => ss.name)
                ];
                // figure out what needs to be added and removed.
                const addedTimePeriods: string[] = arrayDiff(currentTimePeriods, previousTimePeriods);
                const removedTimePeriods: string[] = arrayDiff(previousTimePeriods, currentTimePeriods);
                
                let sortedTimePeriods: string[] = [];
                
                const { values: tpValues, summedSelections: tpSummedSelections } = runConfig.filters.timePeriods;
                if (timePeriodSort === 'asc') {
                    // `timePeriodSort === 'asc'` => BYZ-12159 maintain asc sort for timeperiods if 'asc' sort selected
                    sortedTimePeriods = tpValues
                        .flat()
                        .map((tp) => new TimePeriodRange(tp, maxDate))
                        .sort(TimePeriodRange.compareAsc)
                        .map((tp) => tp.toLegacyDodString());
                    timePeriodConfig.values = [...sortedTimePeriods, ...tpSummedSelections.map((ss) => ss.name)];
                } else if (timePeriodSort === 'desc' || timePeriodSort === 'default' || (timePeriodSort === 'manual' && isQuickLayoutApplied)) {
                    // `timePeriodSort === 'desc'` => BYZ-12159 maintain desc sort for timeperiods if 'desc' sort selected

                    // `timePeriodSort === 'default'` => BYZ-12159 Testcase 3(b) - to maintain reverse chronological order whenever to maintain default order irrespective of new slections after navigating back from layout (or)
                    
                    // `(timePeriodSort === 'manual' && isQuickLayoutApplied)` => BYZ-12159 Testcase 5(b) - to maintain reverse chronological order everytime quicklayout is applied in manual mode (this may happen in rerun)
                    
                    sortedTimePeriods = tpValues
                        .flat()
                        .map((tp) => new TimePeriodRange(tp, maxDate))
                        .sort(TimePeriodRange.compareDesc)
                        .map((tp) => tp.toLegacyDodString());
                    timePeriodConfig.values = [...sortedTimePeriods, ...tpSummedSelections.map((ss) => ss.name)];
                } else {
                    // BYZ-12159 append timeperiods to end if no sort is selected (or 'default' sort is applied)
                    timePeriodConfig.values = [
                        ...timePeriodConfig.values.filter((timePeriod) => !removedTimePeriods.includes(timePeriod)),
                        ...addedTimePeriods,
                    ];
                }
                

                // find previous and current facts
                const factConfig = configs.find(config => config.type === 'facts')!;
                const previousFacts: string[] = factConfig.values;
                const facts = runConfig.facts;

                const currentFacts: string[] = facts.map(factSetToDisplayNames).flat();
                // figure out what needs to be added and removed.
                const addFacts: string[] = arrayDiff(currentFacts, previousFacts);
                const removedFacts: string[] = arrayDiff(previousFacts, currentFacts);
                // remove and add time periods
                factConfig.values = [
                    ...factConfig.values.filter(fact => !removedFacts.includes(fact)),
                    ...addFacts
                ];

                return {
                    ...value,
                    rowColConfigs: cloneDeep(updatedConfigs),
                    runConfig: {
                        ...runConfig,
                        layout: {
                            ...layout,
                            rows: updatedConfigs.filter(v => v.axis === 'row').map(toAxisDef) as DodRowDef[],
                            columns: updatedConfigs.filter(v => v.axis === 'col').map(toAxisDef) as DodColDef[]
                        }
                    }
                };
            })
        },
        setPreset(key, value) {
            setValue(context => ({
                ...context,
                presets: {
                    ...context.presets,
                    [key]: value
                }
            }))
        },
        deletePreset(key) {
            setValue((context) => ({ ...context, presets: omit(context.presets, key) }));
        },
        presets: {},
        setRowColConfigs(value: ((prevState: RowColConfig[]) => RowColConfig[]) | RowColConfig[]): void {
            setValue((prevState) => {
                const rowColConfigs = isFunction(value) ? value(prevState.rowColConfigs) : value;
                // @ts-ignore
                const rows: DodRowDef[] = rowColConfigs.filter((config) => config.axis === 'row').map(toAxisDef);
                const columns: DodColDef[] = rowColConfigs.filter((config) => config.axis === 'col').map(toAxisDef);
                const quickLayoutCode =
                    !prevState.runConfig.layout.templateId && !prevState.runConfig.layout.savedLayoutId
                        ? 'cl'
                        : undefined;
                const runConfig = {
                    ...prevState.runConfig,
                    layout: {
                        ...prevState.runConfig.layout,
                        rows,
                        columns,
                        quickLayoutCode,
                        savedLayoutId: undefined,
                        templateId: undefined,
                    },
                };

                return {
                    ...prevState,
                    rowColConfigs,
                    runConfig,
                };
            });
        },
        suppressQuickLayoutWarning: false,
        setSuppressQuickLayoutWarning(suppressQuickLayoutWarning: boolean): void {
            setValue(value => ({
                ...value,
                suppressQuickLayoutWarning
            }))
        },
        setTimePeriodsReordered(timePeriodsReordered: boolean): void {
            setValue(prevState => ({
                ...prevState,
                timePeriodsReordered
            }))
        },
        timePeriodsReordered: false,
        individualRunSeriesNames: [],
        seriesNames: {
          individual: new Set<string>(),
          scheduled: new Set<string>(),
        },
        setSeriesNames(seriesNames: { [key: string]: Set<string> }): void {
          setValue((prevValue) => ({
              ...prevValue,
              seriesNames,
          }));
        },
        allExceptSelectionFields: [],
        addAllExceptSelectionField(field: string | string[]): void {
            setValue(prevState => ({
                ...prevState,
                allExceptSelectionFields: uniq([...prevState.allExceptSelectionFields, ...(Array.isArray(field) ? field : [field])])
            }))
        },
        removeAllExceptSelectionField(field: string | string[]): void {
            setValue(prevState => ({
                ...prevState,
                allExceptSelectionFields: prevState.allExceptSelectionFields.filter(f => !Array.isArray(field) ? f !== field : !field.includes(f))
            }))
        },
        isAllExceptSelectionField(field: string): boolean {
            return value.allExceptSelectionFields.includes(field);
        },
    });

    return <DodWizardContext.Provider value={value}>
        {children}
    </DodWizardContext.Provider>
}

export const DEFAULT_RUN_CONFIG: DodRunConfig = {
    filters: {
        departments: {
            values: [],
            summedSelections: [],
        },
        superCategories: {
            values: [],
            summedSelections: [],
        },
        categories: {
            values: [],
            summedSelections: [],
        },
        subcategories: {
            values: [],
            summedSelections: [],
        },
        upcs: {
            values: [],
            summedSelections: [],
        },
        productDescriptions: {
            values: [],
            summedSelections: [],
        },
        parentCompanies: {
            values: [],
            summedSelections: [],
        },
        manufacturers: {
            values: [],
            summedSelections: [],
        },
        brands: {
            values: [],
            summedSelections: [],
        },
        characteristics: {},
        customCharacteristics: {},
        ppgs: {},
        markets: {
            values: [],
            summedSelections: [],
        },
        timePeriods: {
            values: [],
            summedSelections: [],
        },
    },
    facts: [],
    layout: {
        rows: [],
        columns: [],
        quickLayoutCode: 'ql1',
        savedLayoutId: undefined,
        includeCategoryTotals: false,
        includeExtractDate: false,
        includeSubTotals: false,
        overallUiOrder: ['products', 'markets', 'time_periods', 'facts']
    },
    conditions: [],
}
export const DEFAULT_ROW_COL_CONFIGS: RowColConfig[] = [
    {
        type: 'products',
        dim: '',
        axis: 'row',
        sortType: 'default',
        hide: false,
        values: [],
        pageBy: false,
        stack: false
    },
    {
        type: 'markets',
        dim: 'markets',
        axis: 'col',
        sortType: 'default',
        hide: false,
        values: [],
        pageBy: false,
        stack: false
    },
    {
        type: 'time_periods',
        dim: 'timePeriods',
        axis: 'col',
        sortType: 'default',
        hide: false,
        values: [],
        pageBy: false,
        stack: false
    },
    {
        type: 'facts',
        dim: 'facts',
        axis: 'col',
        sortType: 'default',
        hide: false,
        values: [],
        pageBy: false,
        stack: false
    },
];
export const excludedDims: (keyof DodFilters | string)[] = ['markets', 'timePeriods', 'facts'];
export const allCharKeys: (keyof DodFilters)[] = ['characteristics', 'customCharacteristics', 'ppgs'];
