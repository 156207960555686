import React, { useState, useEffect, useImperativeHandle, useMemo, useCallback, ReactNode, useRef, forwardRef, useContext } from "react";
import classnames from 'classnames';
import './ProductFilters.scss';
import { FilterGroup, FilterSection } from "@/components/ConfigurationEditors/FilterGroup";
import { ByzzerBrandSearch } from "@/components/ByzzerBrandSearch";
import { ByzzerCategorySelect } from "@/components/ByzzerCategorySelect";
import { SelectorLabelInfo } from "@/components/SelectorLabelInfo";
import { CharacteristicCriteriaBuilder } from "@/components/CharacteristicCriteriaBuilder";
import { ProductRunConfigOptions } from "@/types/RunConfigOptions";
import { DefaultConfigWizardContextValue, DefaultConfigWizardContext } from "../../DefaultsConfigWizard/DefaultsConfigWizardContext";
import { useUser } from "@/contexts/UserContext";
import { useTenantApi } from "@/hooks";
import { useWizardStepContext } from "@byzzer/ui-components";
import { DefaultConfigLabels } from "@/pages/Onboarding/OnboardingSteps";

export type ProductFiltersRef = {
    value?: DefaultConfigWizardContextValue['value'];
}

export type ProductFiltersProps = {
    name?: string;
    onChange?: (e: ByzzerChangeEvent<DefaultConfigWizardContextValue['value']>) => void;
    value?: DefaultConfigWizardContextValue['value'];
    summary?: ReactNode;
    labelsAndTips?: DefaultConfigLabels;
} & Partial<Omit<ProductRunConfigOptions, 'type' | 'title'>> 
  & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

export const MESSAGE_NO_PREFERENCE_CATEGORY_MATCH = 'The categories you have selected do not contain any of your focus brands.';

export const ProductFilters = forwardRef<ProductFiltersRef, ProductFiltersProps>((
    {
        className,
        includeCategories,
        includeCharacteristics,
        includeFocusBrands,
        maxCharacteristics,
        maxCategories,
        maxFocusBrands,
        name,
        value,
        onChange,
        summary,
        labelsAndTips,
        ...props
    }, ref) => {

    const baseClassName = 'byz-product-filters';

    const [internalValue, setInternalValue] = useState<DefaultConfigWizardContextValue['value']>({});

    const { defaultRunConfig } = useUser();
    const { getCategoriesByBrands } = useTenantApi();

    const { value: contextValue, onChange: onContextChange, state: contextState } = useContext(
        DefaultConfigWizardContext
    );

    const { showMessage, hideMessage } = useWizardStepContext();

    const [categoriesToCheckForIntersect, setCategoriesToCheckForIntersect] = useState<string[]>([]);

    useEffect(() => {
        (async () => {
            const { brands } = internalValue;
            const categories = brands?.length ? await getCategoriesByBrands(brands) : [];
            setCategoriesToCheckForIntersect(categories.flat());
        })();
    }, [internalValue?.brands]);

    function handleChange(e: ByzzerChangeEvent<unknown>) {
        onContextChange?.(e.name as keyof typeof defaultRunConfig, e.value, e.data);
    }

    function handleCategoriesChange(e: ByzzerChangeEvent<string[]>) {
        if (!e.value.length) {
            onContextChange?.('characteristics', [], []);
        }
        onContextChange?.(e.name as keyof typeof defaultRunConfig, e.value, e.data);
    }

    useImperativeHandle(ref, () => ({
        get value() {
            return internalValue;
        },
    }));

    useEffect(() => {
        setInternalValue(contextValue ?? {});
    }, [contextValue]);

    useEffect(() => {
        if (value) {
            setInternalValue(value);
        }
    }, [value]);

    useEffect(() => {
        const { brands = [], categories = [] } = internalValue;
        const requiresCheck = Boolean(
            brands.length && categories.length && categoriesToCheckForIntersect.length && includeFocusBrands && includeCategories
        );
        const missingIntersection =
            requiresCheck &&
            !internalValue.categories?.some((category) => categoriesToCheckForIntersect.includes(category));
        if (missingIntersection) {
            showMessage({
                type: 'warning',
                content: MESSAGE_NO_PREFERENCE_CATEGORY_MATCH
            });
        } else {
            hideMessage();
        }
    }, [categoriesToCheckForIntersect, internalValue.categories]);

    return (
        <FilterGroup className={classnames(baseClassName, className)}>
            {summary && <div className={`${baseClassName}__summary`}>{summary}</div>}
            <FilterSection
                onlyRenderIf={
                    includeFocusBrands ||
                    includeCategories
                }
            >
                <ByzzerBrandSearch
                    onlyRenderIf={includeFocusBrands}
                    name={'brands'}
                    maxSelections={maxFocusBrands}
                    value={internalValue.brands}
                    onChange={handleChange}
                    label={labelsAndTips?.focusBrands ?? 'Focus Brand(s)'}
                />
                <ByzzerCategorySelect
                    name={'categories'}
                    onlyRenderIf={includeCategories}
                    value={internalValue.categories}
                    onChange={handleCategoriesChange}
                    placeholder={'Select from the list'}
                    allowClear={true}
                    categoriesToCheckForIntersect={categoriesToCheckForIntersect}
                    label={labelsAndTips?.categories ?? 'Category(s)'}
                    shouldDisplayIntersectIndicators={Boolean(categoriesToCheckForIntersect?.length)}
                    maxSelections={maxCategories}
                    groupOptionsByBrandCoverage={Boolean(categoriesToCheckForIntersect?.length)}
                />

            </FilterSection>
            
                
            <FilterSection onlyRenderIf={includeCharacteristics}>
                <SelectorLabelInfo
                    includeSuffix={false}
                    sku={'57' as string}
                    max={maxCharacteristics}
                    isLabelBold={true}
                    label={"Characteristic(s)"}
                />
                
                <CharacteristicCriteriaBuilder
                    name={'characteristics'}
                    value={internalValue.characteristics}
                    categories={internalValue.categories}
                    maxConditions={maxCharacteristics}
                    onChange={handleChange}
                    includeUpcOption={true} // should eventually be set in Alby
                />
            </FilterSection>
        </FilterGroup>
    );

});

export default ProductFilters;

ProductFilters.displayName = 'ProductFilters';