import {useMemo} from 'react';
import {useUser} from "@/contexts/UserContext";
import {useMarketService} from './market.service';
import {useTenantApi} from '@/hooks';
import {DodCharacteristicFilterMap, DodRunConfig} from '@/types/DodRun';
import {DodPreset, DodPresetSummary, DodPresetType} from '@/types/ApiTypes';

export function useDodService() {

    const {getRunConfigMarketsByKeys} = useMarketService();
    const {dodPresets, defaultRunConfig = {}, runConfigUpcDescription, reloadDodPresets} = useUser();
    const {createDodPreset, deleteDodPreset, getDodPreset} = useTenantApi();

    const dodPresetsMap = useMemo<Record<DodPresetType, (DodPresetSummary | DodPreset)[]>>(() => {

        let {product: products=[], fact: facts, time_period: timePeriods=[], market: markets=[], layout: layout = []} = dodPresets||{};

        const {
            markets: defaultMarkets = [],
            categories: defaultCategories = [],
            brands: defaultBrands = [],
            timePeriod: defaultTimePeriod,
            characteristics: defaultCharacteristics = []
        } = defaultRunConfig;
        let productExcludedFields: string[] = [];

        if (defaultBrands.length || defaultCategories.length || defaultCharacteristics.length) {

            const extractedCharacteristics: Record<
                string,
                { values: string[]; summedSelections: string[] } | DodCharacteristicFilterMap
            > = {
                subcategories: { values: [], summedSelections: [] },
                upcs: { values: [], summedSelections: [] },
                parentCompanies: { values: [], summedSelections: [] },
                manufacturers: { values: [], summedSelections: [] },
                characteristics: {} as DodCharacteristicFilterMap,
                customCharacteristics: {} as DodCharacteristicFilterMap,
            };

            // TODO: Add All Except feature back in OCT-II release
            // defaultCharacteristics.forEach(char => {
            //     switch (char.characteristic) {
            //         case 'unicorn_subcategory':
            //             extractedCharacteristics.subcategories.values = [...char.value];
            //             if(char.condition === 'is not') productExcludedFields.push('subcategories');
            //             break;
            //         case 'upc':
            //             extractedCharacteristics.upcs.values = [...(runConfigUpcDescription|| []).map(u => u.text)];
            //             if(char.condition === 'is not') productExcludedFields.push('upcs');
            //             break;
            //         case 'brand_owner_high':
            //             extractedCharacteristics.parentCompanies.values = [...char.value];
            //             if(char.condition === 'is not') productExcludedFields.push('parentCompanies');
            //             break;
            //         case 'brand_owner':
            //             extractedCharacteristics.manufacturers.values = [...char.value];
            //             if(char.condition === 'is not') productExcludedFields.push('manufacturers');
            //             break;
            //         case 'brand':
            //             break;
            //         default:
            //             if(char.condition === 'is not') productExcludedFields.push(char.characteristic as string);
            //             if(char.isCustom) {
            //                 extractedCharacteristics.customCharacteristics[char.characteristic as string] = {
            //                     characteristic: char.characteristic as string,
            //                     displayName: char.characteristicDisplayValue as string,
            //                     values: char.value,
            //                     summedSelections: []
            //                 };
            //             } else {
            //                 extractedCharacteristics.characteristics[char.characteristic as string] = {
            //                     characteristic: char.characteristic as string,
            //                     displayName: char.characteristicDisplayValue as string,
            //                     values: char.value,
            //                     summedSelections: []
            //                 };
            //             }
            //             break;
            //     }
            // });

            products = [{
                id: -1,
                type: 'product',
                displayName: `My Default Products`,
                values: {
                    brands: {
                        values: defaultBrands,
                        summedSelections: []
                    },
                    categories: {
                        values: defaultCategories,
                        summedSelections: []
                    },
                    departments: {
                        values: [],
                        summedSelections: [],
                    },
                    superCategories: {
                        values: [],
                        summedSelections: [],
                    },
                    subcategories: extractedCharacteristics.subcategories,
                    upcs: extractedCharacteristics.upcs,
                    productDescriptions: {
                        values: [],
                        summedSelections: [],
                    },
                    parentCompanies: extractedCharacteristics.parentCompanies,
                    manufacturers: extractedCharacteristics.manufacturers,
                    characteristics: extractedCharacteristics.characteristics,
                    customCharacteristics: extractedCharacteristics.customCharacteristics,
                }
            } as any, ...products,];
        }

        if (defaultMarkets.length) {

            markets = [{
                id: -1,
                type: 'market',
                displayName: `My Default Markets`,
                values: {
                    markets: {
                        values: getRunConfigMarketsByKeys(defaultMarkets.map(m => m.key!)),
                        summedSelections: []
                    }
                }
            } as any, ...markets,];
        }

        if (defaultTimePeriod) {
            let timePeriod;
            const [type, weeks] = (defaultTimePeriod.period! as string).split(/\s+/);
            if(type === 'Latest') {
                timePeriod = `pe:${weeks}:0`
            } else {
                timePeriod = `pe1y:1y:0`
            }

            timePeriods = [{
                id: -1,
                type: 'time_period',
                displayName: `My Default Time Periods`,
                values: {
                    timePeriods: {
                        values: [timePeriod],
                        summedSelections: []
                    }
                }
            } as any, ...timePeriods,]
        }

        return {
            product: products,
            time_period: timePeriods,
            market: markets,
            fact: facts,
            layout: layout,
            excludedFields: productExcludedFields as any
        };
    }, [dodPresets]);

    async function deletePreset(id: number): Promise<void> {

        // confirmation checks do not belong in this service that is why the alert was removed.
        await deleteDodPreset(id);
        // this isn't necessary we just update the map with the change
        await reloadDodPresets();
    }

    async function getPreset(id: number, runConfig?: DodRunConfig): Promise<DodPreset> {

        const {values, ...preset} = await getDodPreset(id);

        if (preset.type === 'market' && typeof values.markets!.values[0] === 'string') {
            let priorApprovalParams: any = undefined;
            if (
                (values?.categories?.values && values?.categories?.summedSelections) ||
                (runConfig?.filters.categories.values && runConfig?.filters.categories.summedSelections)
            ) {
                const categoryValues = values?.categories?.values || runConfig?.filters.categories.values;
                const categorySummedValues =
                    values?.categories?.summedSelections || runConfig?.filters.categories.summedSelections;
                priorApprovalParams = {
                    isDodMarket: true,
                    categories: categoryValues,
                    isCategoriesSummedMode: categorySummedValues?.length ? true : false,
                    summedCategories: categorySummedValues as any,
                    nonSummedCategories: categoryValues,
                };
            }

            values.markets = {
                values: getRunConfigMarketsByKeys(values.markets!.values as any, priorApprovalParams),
                summedSelections: values.markets!.summedSelections.map((ss) => ({
                    name: ss.name,
                    values: getRunConfigMarketsByKeys(ss.values as any)
                }))
            };
        }

        return {
            ...preset,
            values
        };
    }

    async function addPreset({values, ...preset}: Omit<DodPreset, 'id'>): Promise<DodPreset> {

        switch (preset.type) {
            case "product":
                values = {
                    departments: values.departments,
                    superCategories: values.superCategories,
                    categories: values.categories,
                    subcategories: values.subcategories,
                    upcs: values.upcs,
                    productDescriptions: values.productDescriptions,
                    parentCompanies: values.parentCompanies,
                    manufacturers: values.manufacturers,
                    brands: values.brands,
                    characteristics: values.characteristics,
                    customCharacteristics: values.customCharacteristics,
                }
                break;
            case "market":
                values = {
                    markets: values.markets
                }
                break;
            case "time_period":
                values = {
                    timePeriods: values.timePeriods
                }
                break;
            case "fact":
                break;
        }


        const result = await createDodPreset({
            ...preset,
            values
        });
        // this isn't necessary we just update the map with the change
        await reloadDodPresets();

        return result;
    }

    return {
        dodPresetsMap,
        getPreset,
        deletePreset,
        addPreset

    }
}

type WithTitle = {
    title: string;
}

function compareByLabel(a: WithTitle, b: WithTitle) {
    return a.title.localeCompare(b.title);
}
