import './DodFilterValuePicker.scss';
import React, { ReactNode, useCallback, useEffect, useImperativeHandle, useRef, useState, useMemo } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import classnames from 'classnames';
import { FixedSizeList } from 'react-window';
import { ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { nanoid } from 'nanoid';
import { debounce, difference, intersection, uniq } from 'lodash';
import {
    ByzzerButton,
    ByzzerChangeEventHandler,
    ByzzerCheckableChangeEvent,
    ByzzerCheckbox,
} from '@byzzer/ui-components';
import { AllExceptToggle } from '@/components/DodConfigEditor/common/AllExceptToggle';
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';
import { confirm } from '@/components/form/ByzzerModal';
import { useDodWizard } from '@/components/DodConfigEditor/DodRunConfigWizard/DodWizardContext';
import { useEventDataWithUserInfo, useTrackEvent } from '@/analytics/AnalyticsContext';
import { caseInsensitiveSort } from '@/utils/Sort';
import { DodPresetType } from '@/types/ApiTypes';

const baseClassName = 'dod-filter-value-picker';

type SelectionMode = 'regular' | 'except';

export type DodFilterValuePickerRef = {
    value: string[];
    clear(): void;
    resetFilter(): void;
};

// wrong name, will change later
export type DodValueOption = {
    display?: ReactNode;
    /**
     * Used for searching and sorting.  Will be used for display if display is omitted.
     */
    text: string;
    value: string;
    disabled?: boolean;    
};

export type DodFilterValuePickerProps = {
    name?: string;
    className?: string;
    options: DodValueOption[];
    value?: string[] | 'all';
    onChange?: ByzzerChangeEventHandler<string[] | 'all'>;
    onApply?: ByzzerChangeEventHandler<string[] | 'all'>;
    pickerRef?: React.Ref<DodFilterValuePickerRef>;
    actions?: ActionConfig[];
    includeActions?: boolean;
    loading?: boolean;
    categoryRefreshKey?: string;
    title?: string;
    skipSorting?: boolean;
    onFilterChange?: (filter: string, filterType: string | number | undefined) => Promise<DodValueOption[]> | DodValueOption[];
    filterText?: string;
    limit?: number;
    onLimitExceed?: () => void;
    enableShiftSelection?: boolean;
    enableExplicitAllSelection?: boolean;
    dodWizardStep?: string;
    displaySelectAllOption?: boolean;
    enableAllExcept?: boolean;
    filterType?: string;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

export function DodFilterValuePicker({
    className,
    name,
    value,
    options,
    onChange,
    onApply,
    actions,
    pickerRef,
    includeActions,
    loading,
    categoryRefreshKey,
    skipSorting,
    title,
    onFilterChange,
    filterText,
    limit = Infinity,
    onLimitExceed,
    enableShiftSelection = true,
    enableExplicitAllSelection = false,
    dodWizardStep,
    displaySelectAllOption = true,
    enableAllExcept = false,
    filterType,
    ...props
}: DodFilterValuePickerProps) {
    const {presets, deletePreset,  addAllExceptSelectionField, removeAllExceptSelectionField, allExceptSelectionFields, runConfig, setRunConfig} = useDodWizard();
    const [allOptions, setAllOptions] = useState<DodValueOption[]>([]);
    const [selectedValues, setSelectedValues] = useState<string[]>(() =>
        value === 'all' ? getEnabledValues(allOptions) : value ?? []
    );
    const [includeAll, setIncludeAll] = useState<boolean>(value === 'all');
    const [allListedValuesMatched, setAllListedValuesMatched] = useState<boolean>(false);
    const [matchingOptions, setMatchingOptions] = useState<DodValueOption[]>(allOptions);
    const [selectionMode, setSelectionMode] = useState<SelectionMode>('regular');
    const shiftSelectStartIndex = useRef<number | null>(null);
    const shiftActive = useRef<boolean>(false);
    const hasFilter = Boolean(filterText?.trim());
    const noMatches = (hasFilter && !matchingOptions?.length) || (!matchingOptions?.length && !loading);
    const allLabel = filterText !== '' && matchingOptions.length != allOptions.length ? `All Listed Values` : `All`;
    const filterRequestIdRef = useRef<string | null>(null);

    const trackEvent = useTrackEvent();
    const getEventData = useEventDataWithUserInfo();
    const allOptionsSelected = useMemo(() => {
        return allOptions.length > 0 && allOptions.length === selectedValues.length;
    }, [allOptions, selectedValues, filterType]);
    const { hasSummedChar, hasSummedCustomChar, hasSummedSelections } = useMemo(() => {
        const hasSummedChar = runConfig.filters.characteristics?.[filterType!]?.summedSelections?.length > 0;
        const hasSummedCustomChar =
            runConfig.filters.customCharacteristics?.[filterType!]?.summedSelections?.length > 0;
        return {
            hasSummedChar,
            hasSummedCustomChar,
            hasSummedSelections:
                runConfig.filters?.[filterType!]?.summedSelections?.length > 0 || hasSummedChar || hasSummedCustomChar,
        };
    }, [runConfig, filterType]);

    useImperativeHandle(
        pickerRef,
        () => ({
            get value() {
                return selectedValues;
            },
            set value(value) {
                setSelectedValues(value);
            },
            clear,
            resetFilter,
        }),
        [selectedValues]
    );

    useEffect(() => {
        const sortedOptions =
            skipSorting === true ? options : options.sort((a, b) => caseInsensitiveSort(a.text, b.text));
        setAllOptions(sortedOptions);
        // the change in allOptions will trigger a change in matching options
        // so matching options were not set here
    }, [options, skipSorting]);

    useEffect(() => {
        const allListedValues: ValueType[] = uniq(getEnabledValues(matchingOptions));
        const allListedValuesSelected = intersection(allListedValues, selectedValues).length === allListedValues.length;
        if (!allListedValuesSelected) {
            setIncludeAll(false);
            setAllListedValuesMatched(false);
        } else {
            if(allListedValues.length > 0 && enableExplicitAllSelection) {
                setIncludeAll(true);
            }
            setAllListedValuesMatched(true);
        }
    }, [matchingOptions, selectedValues]);

    useEffect(() => {
        if (value === 'all') {
            setSelectedValues(getEnabledValues(allOptions));
            setIncludeAll(true);
        } else {
            setSelectedValues(value ?? []);
        }
    }, [value, allOptions]);

    useEffect(() => {
        handleFilterChange(allOptions, filterText);
    }, [filterText, allOptions]);

    useEffect(() => {
        try {
            const isExcept =
                allExceptSelectionFields.includes(filterType?.toString() ?? '') ||
                allExceptSelectionFields.includes(filterType!);
            setSelectionMode(isExcept ? 'except' : 'regular');
        } catch (error) {
            console.error(error);
        }
    }, [filterType]);

    useEffect(() => {
        if(selectionMode === 'except' && allOptionsSelected && !loading) {
            handleSelectionModeChange('regular');
        }
    }, [allOptionsSelected, selectionMode]);

    function getEnabledValues(options: DodValueOption[]): string[] {
        return options.filter((option)=> !option.disabled).map((option) => option.value);
    }

    async function handleSelectionChange(e: ByzzerCheckableChangeEvent<string>) {
        let values: string[];
        if(e.checked && selectedValues?.length >= limit) {
            onLimitExceed?.()
        } else {
            if (shiftActive.current) {
                // this means shift select 
                // which will be handled by handleCheckboxClick hence do nothing
                shiftActive.current = false;
                return;
            }
            if (e.checked) {
                setSelectedValues((values) => [...values, e.value]);
                values = [...(value ?? []), e.value];
            } else {
                setSelectedValues((values) => values.filter((v) => v !== e.value));
                values = selectedValues.filter((v) => v !== e.value);
            }
            onChange?.({
                name,
                value: values,
            });
        }
       
    }

    function handleIncludeAllChange(checked, allOptions, filterText: string = '') {
        if(!hasFilter){
            setIncludeAll(checked);
        } else {
            setAllListedValuesMatched(checked);
        }
        const filteredMatchingValues = getEnabledValues(matchingOptions);
        const filteredNonMatchingValues = selectedValues.filter((val) => !filteredMatchingValues.includes(val));
        const allValues = getEnabledValues(allOptions)
        const latestSelectedValues = checked ?
            filterText !== '' 
                ? uniq([...selectedValues, ...filteredMatchingValues])
                : allValues
            : filterText !== '' 
                ? filteredNonMatchingValues
                : difference(selectedValues, allValues) 
                // remove all(current) values from selected values
        setSelectedValues(latestSelectedValues)
        onChange?.({
            name,
            value: checked && filterText === '' ?
                'all' // in this case we need to send 'all' instead of selected values
                : latestSelectedValues,
        });
    }

    function handleClearClick() {
        clear();
    }

    function resetFilter(): void {
        // setFilterText('');
    }

    function handleApplyClick() {
        // remove selected preset values
        if (['product', 'market', 'time_period'].includes(dodWizardStep!) && presets?.[dodWizardStep!]) {
            deletePreset(dodWizardStep! as DodPresetType);
        }

        onApply?.({
            name,
            value: includeAll ? (filterText !== '' ? selectedValues : 'all') : selectedValues,
        });

        if(selectionMode === 'except') {
            addAllExceptSelectionField(filterType!);
            if(hasSummedSelections) {
                setRunConfig((config) => ({
                    ...config,
                    filters: {
                        ...config.filters,
                        ...(!hasSummedChar &&
                            !hasSummedCustomChar && {
                                [filterType!]: {
                                    ...config.filters[filterType!],
                                    summedSelections: [],
                                },
                            }),
                        ...(hasSummedChar && {
                            characteristics: {
                                ...config.filters.characteristics,
                                [filterType!]: {
                                    ...config.filters.characteristics[filterType!],
                                    summedSelections: [],
                                },
                            },
                        }),
                        ...(hasSummedCustomChar && {
                            customCharacteristics: {
                                ...config.filters.customCharacteristics,
                                [filterType!]: {
                                    ...config.filters.customCharacteristics[filterType!],
                                    summedSelections: [],
                                },
                            },
                        }),
                    },
                }));
            }
        } else {
            removeAllExceptSelectionField(filterType!);
        }
    }

    const handleCustomFilterChange = useCallback(
        debounce(async (filterText: string) => {
            const filterRequestId = (filterRequestIdRef.current = nanoid());
            const filteredOptions = await onFilterChange!(filterText, filterType);
            if (filterRequestId === filterRequestIdRef.current) {
                setMatchingOptions(filteredOptions);
            }
        }, 750),
        [filterType]
    );

    async function handleFilterChange(allOptions: DodValueOption[], filterText: string = ''){        
        // invalidate the previous asynchronous process
        filterRequestIdRef.current = null
        if (filterText === '' && allOptions.length > 0) {
            // cancel any pending debounce
            handleCustomFilterChange.cancel();
            setMatchingOptions(allOptions);
        } else if (onFilterChange) {
            await handleCustomFilterChange(filterText);
        } else {
            const matcher = new RegExp(filterText?.trim(), 'i');
            setMatchingOptions(allOptions.filter(({ text }) => matcher.test(text)));
        }
    }

    function handleCheckboxClick(e: React.MouseEvent<HTMLElement>, index: number): void {
        // This function is responsible for handle shift select
        if (!(e.target as HTMLElement).matches('input[type="checkbox"]')) return;
        if(limit != Infinity) {
            // In this case the shift select functionality will be disabled. hence do nothing
            return;
        }

        shiftActive.current = e.shiftKey;

        if (enableShiftSelection && e.shiftKey && shiftSelectStartIndex.current !== null) {
            const start = shiftSelectStartIndex.current > index ? index : shiftSelectStartIndex.current;
            const end = shiftSelectStartIndex.current < index ? index : shiftSelectStartIndex.current;
            const newValues = matchingOptions.slice(start, end + 1).map((option) => {
                if(!option.disabled)
                    return option.value;
            }).filter(Boolean);
            setSelectedValues((values) => {
                const updatedValues = uniq([...values, ...newValues]);
                setTimeout(() => {
                    onChange?.({
                        name,
                        value: updatedValues, 
                    });
                }, 100);

                return updatedValues;
            });
        } else {
            shiftSelectStartIndex.current = index;
        }
    }

    const handleSelectionModeChange = async (mode: SelectionMode) => {
        if(includeAll) {
            handleIncludeAllChange(false, allOptions, filterText)
        }

        if(mode === 'except' && hasSummedSelections) {
            if (
                !(await confirm({
                    content: (
                        <>
                            <p>Entering all except selection mode will remove your summed selections.</p>
                            <p>Would you like to proceed?</p>
                        </>
                    ),
                    noLabel: 'No',
                    yesLabel: 'Yes',
                }))
            ) {
                return;
            }
        }
        setSelectionMode(mode);
    };

    function clear() {
        setSelectedValues([]);
        onChange?.({
            name,
            value: [],
        });
    }

    return (
        <div className={classnames(baseClassName, className)} {...props}>
            <ByzzerMask loading={loading} />
            <header className={`${baseClassName}__header}`}>
                <span className={`dod-filter__header`}>{title}</span>
                {matchingOptions.length > 0 && displaySelectAllOption && 
                    <div className={`${baseClassName}__all-selectors`}>
                        <ByzzerCheckbox
                            checked={filterText !== '' ? allListedValuesMatched : includeAll}
                            label={allLabel}
                            disabled={limit !== Infinity || selectionMode === 'except'}
                            disabledTip={selectionMode === 'except' ? 'You cannot exclude All values from your selections.' : ''}
                            onChange={({ checked }) => handleIncludeAllChange(checked, allOptions, filterText)}
                        />
                    </div>
                }
                
            </header>
            {noMatches && <div className={`${baseClassName}__empty-state`}>No Matching Values Found</div>}
            <div className={`${baseClassName}__options`}>
                <AutoSizer>
                    {({ height, width }) => (
                        <FixedSizeList itemSize={30} height={height} itemCount={matchingOptions.length} width={width}>
                            {({ style, index }) => {
                                const option = matchingOptions[index];
                                const label = option.display ?? option.text;
                                return (
                                    <ByzzerCheckbox
                                        className={classnames(`${baseClassName}__checkbox`, {
                                            [`${baseClassName}__checkbox--except`]: selectionMode === 'except'
                                        })}
                                        key={option.text}
                                        style={style}
                                        checked={selectedValues.includes?.(option.value)}
                                        value={option.value as string}
                                        label={label as string}
                                        disabled={option.disabled}
                                        onClick={(e) => handleCheckboxClick(e, index)}
                                        onChange={handleSelectionChange}
                                    />
                                );
                            }}
                        </FixedSizeList>
                    )}
                </AutoSizer>
            </div>
            {includeActions && (
                <div className={`${baseClassName}__actions`}>
                    {/* TODO: Add All Except feature back in OCT-II release */}
                    {/* {enableAllExcept && (
                        <AllExceptToggle
                            value={selectionMode}
                            onChange={handleSelectionModeChange}
                            className={`${baseClassName}__all-except-toggle`}
                            disabled={allOptionsSelected}
                        />
                    )} */}
                    {actions?.map((config, index) => (
                        <ByzzerButton
                            key={index}
                            type={config.type}
                            className={config.className}
                            iconType={config.icon}
                            tip={config.tip}
                            tipDelay={[500, 0]}
                            label={config.label}
                            onClick={() => {
                              if(config.type === 'summed') {
                                  trackEvent({
                                      type: 'click',
                                      name: 'dod_summed_selections_click',
                                      data: getEventData({ dodWizardStep: dodWizardStep || "", panel: 'value picker' })
                                  })
                              }
                              config.action()
                            }}
                            disabled={config.include === false || (selectionMode === 'except' && config.type === 'summed')}
                            disabledTip={(selectionMode === 'except' && config.type === 'summed') ? 'You cannot sum selections when in All Except selection mode.' : config.disabledTip}
                        />
                    ))}
                    <div className={`${baseClassName}__actions-divider`} />
                    <ByzzerButton type={'negative'} onClick={handleClearClick}>
                        Clear
                    </ByzzerButton>
                    <ByzzerButton onClick={handleApplyClick}>Apply</ByzzerButton>
                </div>
            )}
        </div>
    );
}

export default React.memo(DodFilterValuePicker);