import './DodMarketTreePicker.scss';
import React, { ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { nanoid } from 'nanoid';
import {debounce, differenceBy, unionBy, uniq} from 'lodash';
import {
    ByzzerButton,
    ByzzerChangeEventHandler,
    ByzzerCheckableChangeEvent,
    ByzzerCheckbox,
} from '@byzzer/ui-components';
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';
import { MarketPicker, MarketPickerContext } from '@/components/MarketPicker';
import { useDodWizard } from '@/components/DodConfigEditor/DodRunConfigWizard/DodWizardContext';
import { ZoneLoader } from '@/views/DataOnDemand/Overall/DODLoader';
import {
    isMarketDisabled,
    isMarketHidden,
    marketNodeToRunConfigMarket,
    useMarketService,
} from '@/services/market.service';
import { useUser } from '@/contexts/UserContext';
import { DodFilters } from '@/types/DodRun';
import { RunConfigMarket } from '@/types/ReportRun';

const baseClassName = 'dod-market-tree-picker';


export type DodValueOption = {
    display?: ReactNode;
    /**
     * Used for searching and sorting.  Will be used for display if display is omitted.
     */
    text: string;
    value: string;
    disabled?: boolean;
};

export type DodMarketTreePickerProps = {
    name?: string;
    className?: string;
    options: DodValueOption[];
    value?: RunConfigMarket[];
    normalizedValue?: string[];
    onChange?: ByzzerChangeEventHandler<RunConfigMarket[]>;
    onOptionChange?: ByzzerChangeEventHandler<string[]>;
    onApply?: ByzzerChangeEventHandler<RunConfigMarket[]>;
    actions?: ActionConfig[];
    includeActions?: boolean;
    loading?: boolean;
    categories: string[];
    inSumSelectionMode?: boolean;
    hideTotalUSMarkets?: boolean;
    filterType: 'all' | 'accounts';
    title?: string;
    onFilterChange?: (filter: string) => Promise<DodValueOption[]> | DodValueOption[];
    onOptionApply?: ByzzerChangeEventHandler<string[]>;
    hideGeographicMarkets?: boolean;
    filterText?: string;
    filters?:DodFilters;
    limit?:number;
    onLimitExceed?:() => void,
    updateAccessibleMarketFolders?: (folders) => void
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

export function DodMarketTree({
                                  className,
                                  name,
                                  value,
                                  options,
                                  onChange,
                                  onApply,
                                  actions,
                                  includeActions,
                                  loading,
                                  categories,
                                  inSumSelectionMode,
                                  hideTotalUSMarkets,
                                  filterType,
                                  title,
                                  onOptionChange,
                                  normalizedValue,
                                  onFilterChange,
                                  hideGeographicMarkets,
                                  filterText = '',
                                  onOptionApply,
                                  limit = Infinity,
                                  onLimitExceed,
    filters,
                                  ...props
                              }: DodMarketTreePickerProps) {
    const {
        requiredMasterCompany,
        requiredMarketGroup,
        requireRemainingMarket,
        includePseudoRemainingMarkets,
        showRemainingMarkets,
        hideDisabledMarkets,
        hideNonPromoMarkets
    } = useContext(MarketPickerContext);
    const {
        features: { enableLimitedMarketSubscription },
        accessibleMasterCompanies,
        company,
        features,
    } = useUser();
    const { presets, deletePreset } = useDodWizard();
    const [selectedValues, setSelectedValues] = useState<RunConfigMarket[]>(value ?? []);
    const [filteredOptions, setFilteredOptions] = useState<DodValueOption[]>(options);
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const [areAllSelected, setAreAllSelected] = useState<boolean>(false);
    const [isAllSelectedinList, setIsAllSelectedInList] = useState<boolean>(false);
    const [filterLoading, setFilterLoading] = useState(false);
    const categoriesRefreshKey = `${categories?.sort().join()}`;

    const filterRequestIdRef = useRef<string | null>(null);
    const shiftActive = useRef<boolean>(false);
    const shiftSelectStartIndex = useRef<number | null>(null);
    const { getAllMarkets } = useMarketService();
    
    useEffect(() => {
        if (filterText === '') {
            setSelectedValues(value ?? []);
        } else {
            setSelectedOptions(value?.map((market) => market.name) ?? [])
        }
    }, [filterText, value]);

    useEffect(() => {
        if (!selectedValues.length) {
            setAreAllSelected(false);
        }
    }, [selectedValues]);

    useEffect(() => {
        setFilteredOptions(options ?? []);
    }, [options]);

    const handleCustomFilterChange = useCallback(
        debounce(async (filterText: string) => {
            const filterRequestId = (filterRequestIdRef.current = nanoid());
            const filteredOptions = await onFilterChange!(filterText);
            if (filterRequestId === filterRequestIdRef.current) {
                setFilteredOptions(filteredOptions);
            }
            setFilterLoading(false);
        }, 700),
        [onFilterChange]
    );

    useEffect(() => {
        // this useEffect is focus on loading the options
        // when filterText is empty, all options (from context) are shown by MarketPicker hence, no action needed here
        // TODO: See if accessible markets and normal markets can/should be combined into one tree
        const shouldFilterOptions = Boolean(filterText && !features?.enableLimitedMarketSubscription); // MarketPicker is shown when limited market subscription is enabled, and manages filtering on its own.
        if (shouldFilterOptions) {
            if (onFilterChange) {
                setFilterLoading(true);
                handleCustomFilterChange(filterText);
            } else {
                const matcher = new RegExp(filterText?.trim(), 'i');
                setFilteredOptions(options.filter(({ text }) => matcher.test(text)));
            }
        }
    }, [filterText, options, handleCustomFilterChange]);

    const checkMarketDisabled = useCallback((market: MarketNode): boolean => {
        return isMarketDisabled(market, {
            requiredMarketGroup,
            requiredMasterCompany,
            requireRemainingMarket,            
            enableLimitedMarketSubscription,
            accessibleMasterCompanies,            
            purchasedMarketKeys:company?.purchasedMarketKeys,
        });
    }, [requiredMarketGroup, requiredMasterCompany, requireRemainingMarket])

    const checkMarketHidden = useCallback((market: MarketNode): boolean => {
        return isMarketHidden(market, {
            includePseudoRemainingMarkets,
            requiredMarketGroup,
            requiredMasterCompany,
            requireRemainingMarket,
            showRemainingMarkets,
            hideDisabledMarkets,
            hideNonPromoMarkets,
            enableLimitedMarketSubscription,
            accessibleMasterCompanies,
            purchasedMarketKeys:company?.purchasedMarketKeys,
        });
    }, [includePseudoRemainingMarkets, requiredMarketGroup, requiredMasterCompany, requireRemainingMarket, showRemainingMarkets, hideDisabledMarkets, hideNonPromoMarkets])

    function handleChange(e: ByzzerChangeEvent<RunConfigMarket[]>) {
        let selectedMarkets: RunConfigMarket[] = [...e.value].map((market) => ({
          ...market,
          subMarketType: filterType,
        }));
        if(selectedMarkets?.length > limit){
            onLimitExceed?.()
        } else{
            setSelectedValues(selectedMarkets);
            onChange?.({
                ...e,
                value: selectedMarkets,
            });
        }      
    }

    function handleApplyClick() {
        // remove selected preset values
        if (presets?.market) {
            deletePreset('market');
        }

        if (filterText === '') {
            let selectedMarkets: RunConfigMarket[] = [...value!];
            setSelectedValues(selectedMarkets);
            onApply?.({
                name,
                value: selectedMarkets,
            });
        } else {
            // let selectedMarkets: string[] = [...selectedOptions];
            // setSelectedOptions([]);
            onOptionApply?.({
                name,
                value: selectedOptions,
            });
        }
    }

    function handleClearClick() {
        clear();
    }

    function clear() {
        setSelectedValues([]);
        setSelectedOptions([])
        onChange?.({
            name,
            value: [],
        });
        onOptionChange?.({
            name,
            value: [],
        });
    }

    useEffect(() => {
        const enabledFilteredOptions = uniq(filteredOptions.filter(({ disabled }) => !disabled))
        const selectedFilteredOptions = enabledFilteredOptions.filter(
            (option) => selectedOptions.includes(option.value)
        )
        setIsAllSelectedInList(selectedFilteredOptions.length === enabledFilteredOptions.length)
        // setIsAllSelectedInList(true);
    }, [selectedOptions, filteredOptions]);

    function handleSelectionChange(e: ByzzerCheckableChangeEvent<string>): void {
        let values: string[];
        if (shiftActive.current) {
            // this means shift select 
            // which will be handled by handleCheckboxClick hence do nothing
            shiftActive.current = false;
            return;
        }
        if (e.checked) {
            const selectedMarketsFromList:string[] = [...selectedOptions, e.value]
            if(selectedMarketsFromList?.length > limit){
                onLimitExceed?.()
                return
            }
            setSelectedOptions(selectedMarketsFromList);
            values = [...(normalizedValue ?? []), e.value];
        } else {
            setSelectedOptions((values) => values.filter((v) => v !== e.value));
            values = (normalizedValue ?? []).filter((v) => v !== e.value);
        }
        onOptionChange?.({
            name,
            value: values,
        });
    }

    function handleCheckboxClick(e: React.MouseEvent<HTMLElement>, index: number): void {
        // This function is responsible for handle shift select 
        if (!(e.target as HTMLElement).matches('input[type="checkbox"]')) return;
        if(limit != Infinity) {
            // In this case the shift select functionality will be disabled. hence do nothing
            return;
        }
        shiftActive.current = e.shiftKey;

        if (e.shiftKey && shiftSelectStartIndex.current !== null) {
            const start = shiftSelectStartIndex.current > index ? index : shiftSelectStartIndex.current;
            const end = shiftSelectStartIndex.current < index ? index : shiftSelectStartIndex.current;
            const newValues = (filteredOptions.length ? filteredOptions : options).slice(start, end + 1).filter(option => !option.disabled).map((option) => option.value);
            setSelectedOptions((values) => {
                const updatedValues = uniq([...values, ...newValues]);
                setTimeout(() => {
                    onOptionChange?.({
                        name,
                        value: updatedValues,
                    });
                }, 100);

                return updatedValues;
            });
        } else {
            shiftSelectStartIndex.current = index;
        }
    }

    const getFilteredRunConfigMarkets = useCallback(() => {
        let matchingMarkets: MarketNode[] = []
        const shouldUseAccessibleMarkets = features?.enableLimitedMarketSubscription;
        // inside this component (DodMarketTree), the filterType is either 'all' or 'accounts'
        // since for all other values the parent (DodMarketFilterValuePicker) renders a different component (DodFilterValuePicker)
        if (filterType === 'all') {
            matchingMarkets = getAllMarkets({
                categories,
                includeFmcgRetailers: true,
                includeGeographyMarkets: true,
                includeSpecialityRetailers: true,
                includeTotalUSMarkets: true,
                includePanelTotal: false,
                includePannelByChannel: false,
                // this will be filtered based on result of checkMarketHidden
                includeRemainingMarkets: true,
                purchasedMarketKeys: company?.purchasedMarketKeys,
                isDodMarketPicker: true,
                hasSummedCategories: filters?.categories?.summedSelections.length ? true : false,
                summedCategories: filters?.categories?.summedSelections.map((item) => item.values).flat(),
                nonSummedCategories: filters?.categories.values as string[],
                isMarketSummedMode:inSumSelectionMode,
                shouldUseAccessibleMarkets,
            });
        } else if (filterType === 'accounts') {
            matchingMarkets = getAllMarkets({
                categories,
                includeFmcgRetailers: true,
                includeGeographyMarkets: false,
                includeSpecialityRetailers: true,
                includeTotalUSMarkets: false,
                includePanelTotal: false,
                includePannelByChannel: false,
                // this will be filtered based on result of checkMarketHidden
                includeRemainingMarkets: true,
                purchasedMarketKeys: company?.purchasedMarketKeys,
                isDodMarketPicker: true,
                hasSummedCategories: filters?.categories?.summedSelections.length ? true : false,
                summedCategories: filters?.categories?.summedSelections.map((item) => item.values).flat(),
                nonSummedCategories: filters?.categories.values as string[],
                isMarketSummedMode:inSumSelectionMode,
            });
        } else {
            matchingMarkets = getAllMarkets({
                categories,
                includeFmcgRetailers: true,
                includeGeographyMarkets: true,
                includeSpecialityRetailers: true,
                includeTotalUSMarkets: true,
                includePanelTotal: true,
                includePannelByChannel: true,
                // this will be filtered based on result of checkMarketHidden
                includeRemainingMarkets: true,
                purchasedMarketKeys: company?.purchasedMarketKeys,
                isDodMarketPicker: true,
                hasSummedCategories: filters?.categories?.summedSelections.length ? true : false,
                summedCategories: filters?.categories?.summedSelections.map((item) => item.values).flat(),
                nonSummedCategories: filters?.categories.values as string[],
                isMarketSummedMode:inSumSelectionMode,
            });
        }

        return matchingMarkets
            .filter(
                (market) =>
                    market.selectable === true && // to prevent folders from being selected
                    !checkMarketDisabled(market) && // to prevent disabled markets from being selected
                    !checkMarketHidden(market) // to prevent hidden markets from selected
            )
            .map((node) => marketNodeToRunConfigMarket(node, filterType)); // filterType is either 'all' or 'accounts' and it is used in edit summed selection logic to select market type
    }, [checkMarketDisabled, checkMarketHidden, filterType])

    useEffect(() => {
        // this useEffect is responsible for unsetting the `areAllSelected` state
        // based on latest all options and selected values
        setAreAllSelected(() => {
            const filteredRunConfigMarkets = getFilteredRunConfigMarkets();
            const selectedNames = new Set(selectedValues.map((opt) => opt.name));

            if (filterText) {
                const matcher = new RegExp(filterText.trim(), 'i');
                const enabledFilteredOptions = filteredRunConfigMarkets.filter(({ name }) => matcher.test(name));
                return (
                    enabledFilteredOptions.length > 0 &&
                    enabledFilteredOptions.every((option) => selectedNames.has(option.name))
                );
            }

            return (
                filteredRunConfigMarkets.length > 0 &&
                filteredRunConfigMarkets.every((option) => selectedNames.has(option.name))
            );
        });
    }, [filterText, selectedValues, getFilteredRunConfigMarkets]);

    function handleAllChange(checked: boolean, getFilteredRunConfigMarkets) {
        setAreAllSelected(checked);
        let allFilteredRunConfigMarkets = getFilteredRunConfigMarkets();
        if(features?.enableLimitedMarketSubscription) {
            const matcher = new RegExp(filterText?.trim(), 'i');
            allFilteredRunConfigMarkets = allFilteredRunConfigMarkets.filter(({ name }) => matcher.test(name));
        }
        setSelectedValues((prev) => {
            let latestSelectedMarkets: RunConfigMarket[];
            if (checked) {
                // add all(current) values to selected values
                latestSelectedMarkets = unionBy(prev, allFilteredRunConfigMarkets, 'name');
            } else {
                // remove all(current) values from selected values
                latestSelectedMarkets = differenceBy(prev, allFilteredRunConfigMarkets, 'name');
            }
            onChange?.({
                name,
                value: latestSelectedMarkets,
            });
            return latestSelectedMarkets;
        });
    }

    function handleAllinListChange(checked, filteredOptions: DodValueOption[]) {
        setIsAllSelectedInList(checked);
        // hidden options will not reach here so no need to check
        const filteredMatchingValues = filteredOptions.filter(({ disabled }) => !disabled).map((val) => val.value);
        setSelectedOptions((selectedMarketNames) => {
            const filteredNonMatchingValues = selectedMarketNames.filter(
                (val) => !filteredMatchingValues.includes(val)
            );
            const latestSelectedOptions = checked
                ? uniq([...selectedMarketNames, ...filteredMatchingValues])
                : filteredNonMatchingValues;
            onOptionChange?.({
                name,
                value: latestSelectedOptions,
            });
            return latestSelectedOptions;
        });
    }
    // MarketPicker is shown when limited market subscription is enabled, which manages filtering on its own.  ToDo: See if MarketPicker can be refactored to use AutoSizer to list markets
    const showMarketPicker = filterText === '' || features?.enableLimitedMarketSubscription;

    return (
        <div className={classnames(baseClassName, className)} {...props}>
            <ByzzerMask loading={loading}/>

            {showMarketPicker ? (
                <>
                {!inSumSelectionMode && 
                    <header className={`${baseClassName}__header}`}>
                        <ByzzerCheckbox
                            disabled={limit !== Infinity}
                            checked={areAllSelected}
                            label={ filterText ? 'All Listed Values' :'All' }
                            onChange={({ checked }) => handleAllChange(checked, getFilteredRunConfigMarkets)}
                        />
                    </header>
                }
                    <div className={`${baseClassName}__options`}>
                        <MarketPicker
                            key={categoriesRefreshKey}
                            value={selectedValues}
                            onChange={handleChange}
                            maxSelections={Infinity}
                            categories={categories}
                            name={'markets'}
                            productSku={'57'}
                            hideTotalUSMarkets={hideTotalUSMarkets}
                            showRemainingMarkets={showRemainingMarkets}
                            hideDisabledMarkets={hideDisabledMarkets}
                            hideNonPromoMarkets={hideNonPromoMarkets}
                            displayMarketGroup="rms"
                            hideGeographicMarkets={hideGeographicMarkets}
                            isDodMarketPicker={true}
                            purchasedMarketKeys={company?.purchasedMarketKeys}
                            hasSummedCategories = {filters?.categories?.summedSelections.length?true:false}                            
                            summedCategories={ filters?.categories?.summedSelections.map((item)=>  item.values).flat() } 
                            nonSummedCategories={filters?.categories.values as string[]} 
                            filterText={filterText}
                            isMarketSummedMode={inSumSelectionMode}
                            updateAccessibleMarketFolders={props?.updateAccessibleMarketFolders}
                            filterType={filterType}
                        />
                    </div>
                </>
            ) : (
                <>
                    {
                        filterLoading ? (
                            <ZoneLoader />
                        ) : (
                            filteredOptions.length ? (
                                <>
                                {!inSumSelectionMode &&
                                <header className={`${baseClassName}__header}`}>
                                    <ByzzerCheckbox
                                        checked={isAllSelectedinList}
                                        disabled = {limit !== Infinity}
                                        label={'All Listed Values'}
                                                onChange={({ checked }) => handleAllinListChange(checked, filteredOptions)}
                                    />
                                </header>
                              }
                              </>
                            ) : (
                                <div className={`${baseClassName}__empty-state`}>No Matching Values Found</div>

                            )
                        )
                    }
                    <div className={`${baseClassName}__options`}>
                        <AutoSizer>
                            {({height, width}) => (
                                <FixedSizeList
                                    itemSize={30}
                                    height={height}
                                    itemCount={filteredOptions.length}
                                    width={width}
                                >
                                    {({ style, index }) => {
                                        const option = filteredOptions[index];
                                        const label = option.display ?? option.text;
                                        return (
                                            <ByzzerCheckbox
                                                key={option.text}
                                                style={style}
                                                checked={selectedOptions.includes(option.value)}
                                                value={option.value}
                                                label={label}
                                                disabled={option.disabled}
                                                onClick={(e) => handleCheckboxClick(e, index)}
                                                onChange={handleSelectionChange}
                                            />
                                        );
                                    }}
                                </FixedSizeList>
                            )}
                        </AutoSizer>
                    </div>
                </>
            )}

            {includeActions && (
                <div className={`${baseClassName}__actions`}>
                    {actions?.map((config, index) => (
                        <ByzzerButton
                            key={index}
                            type={config.type}
                            className={config.className}
                            iconType={config.icon}
                            tip={config.tip}
                            tipDelay={[500, 0]}
                            label={config.label}
                            onClick={() => config.action()}
                            disabled={config.include === false}
                        />
                    ))}
                    <div className={`${baseClassName}__actions-divider`}/>
                    <ByzzerButton type={'negative'} onClick={handleClearClick}>
                        Clear
                    </ByzzerButton>
                    <ByzzerButton onClick={handleApplyClick}>Apply</ByzzerButton>
                </div>
            )}
        </div>
    );
}