import './DodFilterPreview.scss';
import React, {memo, ReactNode, useEffect, useMemo, useState} from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import classnames from 'classnames';
import {cloneDeep} from 'lodash';
import {FixedSizeList} from 'react-window';
import {ByzzerChangeEventHandler} from '@byzzer/ui-components';
import {ErrorBoundary} from "react-error-boundary";
import {format as formatDate} from "date-fns";
import {nanoid} from 'nanoid';

import {openCreateDodPresetModal} from '../DodSavedSelection/DodSaveSelectionModal';
import {confirmMarketReset} from '@/components/DodConfigEditor/builders';
import {
    isCharacteristicFilter,
    isPpgFilter,
    isMarketResetRequired,
    isParentProductFilterTypes,
    isProductFilterTypes,
    isTimePeriodFilterTypes
} from '@/components/DodConfigEditor/common/utils';
import {
    DodFilterType,
    DodProductFilterTypes,
    DodSavedSelectionTypes,
    DodTimePeriodFilterTypes,
    DodTimePeriodFilterTypeValueMap
} from '@/components/DodConfigEditor/types';
import {DodPanel} from "@/components/DodConfigEditor/common/DodPanel";
import {useDodWizard} from "@/components/DodConfigEditor/DodRunConfigWizard/DodWizardContext";
import {useEventDataWithUserInfo, useTrackEvent} from "@/analytics/AnalyticsContext";
import {isValidArray} from '@/utils';
import {timePeriodToString} from '@/services/timePeriod.service';
import {useCategoryService} from '@/services/category.service';
import {useApp} from '@/contexts/UserContext';
import {DodFilter, DodFilters, SummedSelection} from '@/types/DodRun';
import {DodPresetType} from '@/types/ApiTypes';
import { useDodService } from '@/services/dodPresets.service';

const baseClassName = 'dod-filter-preview';

export type DodSelectionPreviewNode = {
    key?: string;
    label: ReactNode;
    tip?: ReactNode;
    expanded?: boolean;
    filterType?: string | DodFilterType;
    children?: DodSelectionPreviewNode[];
    loadChildren?(filters: DodFilters): DodSelectionPreviewNode[];
    hasValues?: boolean;
    depth?: number;
} & Partial<DodFilter>;

type DodFilterPreviewValueProps = {
    value: string;
    onDelete(value: string, group: DodSelectionPreviewNode): void;
    group: DodSelectionPreviewNode;
    depth?: number;
    maxDate?: string;
};

type DodFilterPreviewGroupProps = {
    group: DodSelectionPreviewNode;
    onDelete(group: DodSelectionPreviewNode): void;
    onDeleteValue(value: string, group: DodSelectionPreviewNode): void;
    onEditSummedSelection(group: DodSelectionPreviewNode, index: number): void;
    onDeleteSummedSelection(group: DodSelectionPreviewNode, index: number): void;
    onDeleteSummedSelectionValue(group: DodSelectionPreviewNode, index: number, value: string): void;
    depth?: number;
    maxDate?: string;
    skipSorting?: boolean;
};

export function DodFilterPreviewValue({value, onDelete, group, depth = 1, maxDate}: DodFilterPreviewValueProps) {
    const filterType = getFilterType(group.filterType);

    function handleDelete(): void {
        onDelete(value, group);
    }

    return (
        <ErrorBoundary fallback={<>Node Failure</>} onError={handleError}>
            <div className={classnames(`${baseClassName}__node`, `${baseClassName}__value`, {
                [`${baseClassName}__node--depth-${depth}`]: depth
            })}>
                {/* @ts-ignore temporary fix for facts. need to correct this */}
                {value?.label ? value?.label
                    : isTimePeriodFilterTypes(filterType?.type as DodTimePeriodFilterTypes)
                        ? timePeriodToString(value, maxDate)
                        : value}
                <div className={`${baseClassName}__actions`}>
                    <i className={classnames(`${baseClassName}__action`, `${baseClassName}__action--delete`)}
                       onClick={handleDelete}/>
                </div>
            </div>
        </ErrorBoundary>
    );
}

export function DodFilterPreviewGroup({
                                          group,
                                          onDelete,
                                          onDeleteValue,
                                          onDeleteSummedSelection,
                                          onDeleteSummedSelectionValue,
                                          onEditSummedSelection,
                                          depth = 0,
                                          maxDate,
                                          skipSorting = false
                                      }: DodFilterPreviewGroupProps) {

    const [expanded, setExpanded] = useState<boolean>(true);
    const includeAll = group.values === 'all';

    function handleExpand(): void {
        setExpanded((expanded) => !expanded);
    }

    function handleDelete(): void {
        onDelete?.(group);
    }

    return (<>
        <div className={classnames(`${baseClassName}__node`, `${baseClassName}__group`, {
            [`${baseClassName}__group--expanded`]: expanded,
            [`${baseClassName}__node--depth-${depth}`]: depth
        })}>
            <div className={`${baseClassName}__group-label`} onClick={handleExpand}>
                {group.label}
            </div>
            <div className={`${baseClassName}__actions`}>
                <i className={classnames(`${baseClassName}__action`, `${baseClassName}__action--delete`)}
                   onClick={handleDelete}/>
            </div>
        </div>
        {expanded && includeAll && <>
            <DodFilterPreviewValue key={'includeAll'} value={'All'} depth={depth + 1}
                                   onDelete={handleDelete} group={group}/>
        </>
        }
        {expanded && group.children?.map((group) => (
            <DodFilterPreviewGroup key={group.key}
                                   group={group}
                                   depth={depth + 1}
                                   onDelete={onDelete}
                                   onDeleteSummedSelection={onDeleteSummedSelection}
                                   onDeleteSummedSelectionValue={onDeleteSummedSelectionValue}
                                   onDeleteValue={onDeleteValue}
                                   onEditSummedSelection={onEditSummedSelection}
                                   maxDate={maxDate}
            />
        ))}
        {expanded && !includeAll && (skipSorting ? (group.values as string[]) : (group.values as string[])?.sort())?.map((value) => (
            <DodFilterPreviewValue key={value} value={value} depth={depth + 1}
                                   onDelete={onDeleteValue} group={group} maxDate={maxDate}/>
        ))}

        {expanded && group.summedSelections?.map((summedSelection, index) => (
            <DodFilterPreviewSummedSelection key={index}
                                             depth={depth + 1}
                                             summedSelection={summedSelection}
                                             onEdit={onEditSummedSelection}
                                             onDelete={onDeleteSummedSelection}
                                             onDeleteValue={onDeleteSummedSelectionValue}
                                             group={group}
                                             index={index}
                                             maxDate={maxDate}
            />
        ))}
    </>);
}

type DodFilterPreviewSummedSelectionProps = {
    summedSelection: SummedSelection;
    onEdit(group: DodSelectionPreviewNode, index: number): void;
    onDelete(group: DodSelectionPreviewNode, index: number): void;
    onDeleteValue(group: DodSelectionPreviewNode, index: number, value: string): void;
    group: any;
    index: number;
    depth?: number;
    maxDate?: string;
};

function DodFilterPreviewSummedSelection({
                                             summedSelection,
                                             index,
                                             group,
                                             onDelete,
                                             onEdit,
                                             onDeleteValue,
                                             depth = 1,
                                             maxDate,
                                         }: DodFilterPreviewSummedSelectionProps) {
    const [expanded, setExpanded] = useState<boolean>(false);
    const includeAll = summedSelection.values === 'all';

    function handleEdit() {
        onEdit?.(group, index);
    }

    function handleDelete(): void {
        onDelete?.(group, index);
    }

    function handleLabelClick(): void {
        setExpanded((expanded) => !expanded);
    }

    function handleValueDelete(value: string): void {
        onDeleteValue(group, index, value);
    }

    return (<>
        <div
            className={classnames(`${baseClassName}__node`, `${baseClassName}__summed-selection`, {
                [`${baseClassName}__node--expanded`]: expanded,
                [`${baseClassName}__node--depth-${depth}`]: depth,
                [`${baseClassName}__summed-selection--expanded`]: expanded,
            })}
        >
            <div className={`${baseClassName}__summed-selection-label`} onClick={handleLabelClick}>
                {summedSelection.name}
            </div>
            <div className={`${baseClassName}__actions`}>
                <i className={classnames(`${baseClassName}__action`, `${baseClassName}__action--edit`)}
                   onClick={handleEdit}/>
                <i className={classnames(`${baseClassName}__action`, `${baseClassName}__action--delete`)}
                   onClick={handleDelete}/>
            </div>
        </div>
        {expanded && includeAll && <><DodFilterPreviewValue value={'All'} group={group} depth={depth + 1}
                                                            onDelete={handleDelete}/></>}
        {expanded &&
            !includeAll &&
            (summedSelection.values as string[]).map((value) => (
                <DodFilterPreviewValue value={value} group={group} depth={depth + 1}
                                       onDelete={handleValueDelete} maxDate={maxDate}/>
            ))}
    </>);
}

type FilterPreviewNode = {
    key: string | undefined;
    label: string;
    value: string;
    depth?: number;
    index?: number;
    groupIndex?: number;
    collapsed?: boolean;
    isGroup?: boolean;
    isChild?: boolean;
    isSummedGroup?: boolean;
    isSummedSelectionChild?: boolean;
    filterType?: string | DodFilterType | undefined;
    parentFilterType?: string | DodFilterType | undefined;
};
type DodFilterPreviewNodeProps = {
    data: FilterPreviewNode;
    onDelete?(): void;
    onEdit?(): void;
    onCollapse?(): void;
    style: React.CSSProperties;
};
const DodFilterPreviewNode = ({data, style, onDelete, onEdit, onCollapse}: DodFilterPreviewNodeProps) => {
    const { allExceptSelectionFields: rawAllExceptSelectionFields } = useDodWizard();
    const allExceptSelectionFields = rawAllExceptSelectionFields.map((field) => String(field));

    const expanded = !data?.collapsed;
    const isGroup = data?.isGroup || data?.isSummedGroup;
    const isExcludedChild = allExceptSelectionFields.includes(typeof data?.parentFilterType === 'object' ? data?.parentFilterType?.data?.id : data?.parentFilterType as string);
    const isExcludedParent = allExceptSelectionFields.includes(typeof data?.filterType === 'object' ? data?.filterType?.data?.id : data?.filterType as string);
    return (
        <div
            className={classnames(`${baseClassName}__node`, {
                [`${baseClassName}__group`]: data?.isGroup,
                [`${baseClassName}__value`]: data?.isChild || data?.isSummedSelectionChild,
                [`${baseClassName}__summed-selection`]: data?.isSummedGroup,
                [`${baseClassName}__group--expanded`]: data?.isGroup && expanded,
                [`${baseClassName}__summed-selection--expanded`]: data?.isSummedGroup && expanded,
                [`${baseClassName}__node--depth-${data?.depth}`]: data?.depth,
                [`${baseClassName}__node--excluded-child`]: isExcludedChild,
                [`${baseClassName}__node--excluded-parent`]: isExcludedParent,
            })}
            style={style}
        >
            <div
                className={classnames({
                    [`${baseClassName}__group-label`]: data?.isGroup,
                    [`${baseClassName}__summed-selection-label`]: data?.isSummedGroup,
                })}
                onClick={isGroup ? onCollapse : undefined}
            >
                {data.label}
            </div>
            <div className={`${baseClassName}__actions`}>
                {data?.isSummedGroup && (
                    <i
                        className={classnames(`${baseClassName}__action`, `${baseClassName}__action--edit`)}
                        onClick={onEdit}
                    />
                )}
                <i
                    className={classnames(`${baseClassName}__action`, `${baseClassName}__action--delete`)}
                    onClick={onDelete}
                />
            </div>
        </div>
    );
};

export type DodFilterPreviewProps = {
    value: DodFilters;
    onChange?: ByzzerChangeEventHandler<DodFilters>;
    initialGroups: DodSelectionPreviewNode[];
    emptyStateContent?: ReactNode;
    onDeleteFilterGroup?(type: DodFilterType, filterTypes?: DodFilterType[]): void;
    onDeleteFilterValue?(type: DodFilterType, value: string): void;
    onEditSummedSelection?(type: DodFilterType, index: number): void;
    onDeleteSummedSelection?(type: DodFilterType, index: number): void;
    onDeleteSummedSelectionValue?(type: DodFilterType, index: number, value: string): void;
    resetFactSelections?(filterType: string): void;
    title?: string;
    savedSelectionType: DodPresetType;
    savedSelectionDescription: ReactNode;
    disableDelete?: boolean;
    disableEdit?: boolean;
    disableSave?: boolean;
    skipSorting?: boolean;
    selectionCount?: number;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

export const DodFilterPreview = memo(({
                                          className,
                                          value,
                                          onChange,
                                          initialGroups,
                                          emptyStateContent,
                                          onDeleteFilterGroup,
                                          onDeleteFilterValue,
                                          onDeleteSummedSelection,
                                          onDeleteSummedSelectionValue,
                                          onEditSummedSelection,
                                          resetFactSelections,
                                          savedSelectionType,
                                          savedSelectionDescription,
                                          title = 'Your Selected Items',
                                          selectionCount = 0,
                                          disableDelete = false,
                                          disableEdit = false,
                                          disableSave = false,
                                          skipSorting = false,
                                          ...props
                                      }: DodFilterPreviewProps) => {

    const [nonEmptyGroups, setNonEmptyNodes] = useState<DodSelectionPreviewNode[]>([]);
    const [collapsedAndExpandedNodes, setCollapsedAndExpandedNodes] = useState<{ [key: string]: boolean }>({});
    const isEmpty = Boolean(value && nonEmptyGroups?.length === 0);
    const {presets, setPreset, deletePreset, removeAllExceptSelectionField} = useDodWizard();

    const {maxDataDates, reloadDodPresets} = useApp();
    const trackEvent = useTrackEvent();
    const getEventData = useEventDataWithUserInfo();
    const {dodPresetsMap} = useDodService();
    const maxDate = useMemo<string>(() => {
        return formatDate(maxDataDates.rms!, 'yyyyMMdd');
    }, [maxDataDates]);
    const previewTitle = useMemo<JSX.Element>(() => {
        const displayName = presets[savedSelectionType]?.displayName;
        return !displayName ? (
            <span>{title}</span>
        ) : (
            <div className={`${baseClassName}__selection`}>
                {`${selectionCount} selected using`}{' '}
                <span className={`${baseClassName}__preset-name`} data-title={presets[savedSelectionType]?.displayName}>
                    {presets[savedSelectionType]?.displayName}
                </span>
            </div>
        );
    }, [title, presets, selectionCount]);

    useEffect(() => {
        const nonEmptyNodes: DodSelectionPreviewNode[] = initialGroups.map(toPreviewNode).filter(nodeHasValues);
        setNonEmptyNodes(nonEmptyNodes);
    }, [value]);

    function getFlattenedPreviewNodes(): FilterPreviewNode[] {
        try {
            let result = [];
            const clonedNonEmptyNodes = cloneDeep(nonEmptyGroups);
            for (const iterator of clonedNonEmptyNodes) {
                flattenFilterPreviewNodes(iterator, 0, result);
            }
            return result;
        } catch (error) {
            console.error('Error generating filter preview nodes:', error);
            return [];
        }
    }

    function removeMarketPreset() {
        deletePreset('market');
    }

    const handleSaveSelections = async () => {
        trackEvent({
            type: 'click',
            name: 'dod_save_selections_click',
            data: getEventData({ dodWizardStep: savedSelectionType, panel: 'filter preview' })
        })
        const preset = await openCreateDodPresetModal({
            description: savedSelectionDescription,
            preset: {
                type: savedSelectionType,
                values: value,
            },
            currentPreset: presets[savedSelectionType],
            dodPresetsMap: dodPresetsMap[savedSelectionType]
        });

        if (preset) {
            setPreset(savedSelectionType, {
                ...preset,
                // @ts-ignore - this needs to be here to remove values and produce a valid preset summary
                values: undefined
            })
            reloadDodPresets();
        }
    };

    async function handleDeleteGroup(filter: DodFilterType | string | undefined, index = 0) {
        if (filter === undefined) return;
        const {type: filterType, data: filterData} = getFilterType(filter) || {};

        let updatedValues = cloneDeep(value);

        if (isTimePeriodFilterTypes(filterType as DodTimePeriodFilterTypes)) {
            if (filterType === DodTimePeriodFilterTypes.CUSTOM_TIME_PERIOD) {
                let customTimePeriods = updatedValues['timePeriods'].values.filter((item) => isValidArray(item));
                customTimePeriods.splice(index, 1);
                updatedValues['timePeriods'].values = [
                    ...updatedValues['timePeriods'].values.filter((item) => !isValidArray(item)),
                    ...customTimePeriods,
                ];
            } else {
                const timePeriods = updatedValues['timePeriods'];
                const filterValue = DodTimePeriodFilterTypeValueMap[filterType || ''];
                updatedValues['timePeriods'] = {
                    ...timePeriods,
                    values: filterType === 'timePeriods' ? []
                        : timePeriods.values.reduce((acc: string[], val) => {
                            if (val.slice(0, 2) !== filterValue) {
                                acc.push(val as string);
                            }
                            return acc;
                        }, []),
                    summedSelections: filterType === 'timePeriods' ? []
                        : filterType === 'summedTimePeriods' ? []
                            : timePeriods.summedSelections,
                };
            }
        } else if (filterType === "markets" || filterType === "marketTypes") {
            const markets = updatedValues['markets'];
            updatedValues['markets'] = {
                ...markets,
                values: filterType === 'marketTypes' ? []
                    : markets.values.filter(
                        (item) => item.selectionType !== filterData
                    ),
                summedSelections: filterType === 'marketTypes' ? []
                    : filterData === 'summed' ? []
                        : markets.summedSelections,
            };
        } else if (isProductFilterTypes(filterType as DodProductFilterTypes) || isParentProductFilterTypes(filterType as DodProductFilterTypes)) {
            let filterTypes: DodFilterType[] | undefined = undefined;
            const group = initialGroups.find((grp) => grp.filterType === filterType);
            if (group?.children?.length) {
                filterTypes = group?.children?.map((child: DodSelectionPreviewNode) =>
                    getFilterType(child.filterType)
                ) as DodFilterType[];
            }
            const filterTypeCollection = [...(Array.isArray(filterTypes) ? filterTypes : [{
                type: filterType,
                data: filterData?.id || filterData
            } as DodFilterType])]

            //* reset market and facts
            let requiresMarketReset = false;
            let requiresFactReset = false;
            let resetFactFilter = '';
            for (const type of filterTypeCollection) {
                if (!requiresMarketReset && isMarketResetRequired(type, value)) {
                    requiresMarketReset = true;
                }
                if (!requiresFactReset && (type?.type === 'upcs' || type?.type === 'brands')) {
                    requiresFactReset = true;
                    resetFactFilter = type?.type;
                }
            }
            if (requiresMarketReset && !(await confirmMarketReset())) return;
            if (requiresMarketReset) {
                // todo: only remove markets don't match the selected categories
                updatedValues.markets = {
                    values: [],
                    summedSelections: [],
                };
                removeMarketPreset();
            }
            if (requiresFactReset) {
                resetFactSelections?.(resetFactFilter);
            }

            filterTypeCollection.forEach((filter) => {
                if (isCharacteristicFilter(filter) || isPpgFilter(filter)) {
                    if (!filter.data) {
                        updatedValues[filter.type] = {};
                    } else {
                        delete updatedValues[filter.type][filter.data];
                    }
                } else {
                    updatedValues[filter.type] = {
                        values: [],
                        summedSelections: [],
                    } as DodFilter;
                }
            });

            removeAllExceptSelectionField(filterTypes ? (filterTypes.map(filter => filter.type)) : (filterType as string));
        }

        onChange?.({value: updatedValues});
        deletePreset(savedSelectionType);
    }

    async function handleDeleteGroupValue(filter: DodFilterType | string | undefined, val: string, index = 0) {
        if (filter === undefined) return;
        const {type: filterType, data: filterData} = getFilterType(filter) || {};

        let updatedValues = cloneDeep(value);

        if (isTimePeriodFilterTypes(filterType as DodTimePeriodFilterTypes)) {
            if (filterType === DodTimePeriodFilterTypes.CUSTOM_TIME_PERIOD) {
                let customTimePeriods = updatedValues['timePeriods'].values.filter((item) => isValidArray(item));
                customTimePeriods![index] = (customTimePeriods![index] as string[]).filter((item) => item !== val);
                if(!isValidArray(customTimePeriods![index])) {
                  customTimePeriods.splice(index, 1);
                }
                updatedValues['timePeriods'].values = [
                    ...updatedValues['timePeriods'].values.filter((item) => !isValidArray(item)),
                    ...customTimePeriods,
                ];
            } else {
                updatedValues['timePeriods'] = {
                    ...updatedValues['timePeriods'],
                    values: updatedValues['timePeriods'].values.filter((item) => {
                        if(val === 'all'){
                            return item.slice(0,2) !== DodTimePeriodFilterTypeValueMap[filter]
                        }else {
                            return item !== val
                        }
                    }),
                };
            }
        } else if (filterType === 'markets') {
            updatedValues['markets'] = {
                ...updatedValues['markets'],
                values: updatedValues['markets'].values.filter((item) => item.name !== val),
            };
        } else if (isProductFilterTypes(filterType as DodProductFilterTypes)) {
            const filterDataVal: string = filterData?.id || filterData;
            const requiresMarketReset = isMarketResetRequired(
                {type: filterType, data: filterDataVal} as DodFilterType,
                value
            );
            if (requiresMarketReset && !(await confirmMarketReset())) return;

            if (requiresMarketReset) {
                // todo: only remove markets don't match the selected categories
                updatedValues['markets'] = {
                    values: [],
                    summedSelections: [],
                };
                removeMarketPreset();
            }
            if (
                (filterType === 'upcs' || filterType === 'brands') &&
                (val === 'all' ||
                    (updatedValues[filterType].values.length === 1 &&
                        !updatedValues[filterType].summedSelections.length))
            ) {
                resetFactSelections?.(filterType);
            }

            if (isCharacteristicFilter({type: filterType, data: filterDataVal} as DodFilterType) || isPpgFilter({type: filterType, data: filterDataVal} as DodFilterType)) {
                const type = filterType as DodProductFilterTypes;
                updatedValues[type][filterDataVal] = {
                    ...updatedValues[type][filterDataVal],
                    values: updatedValues[type][filterDataVal].values === 'all' ? [] : updatedValues[type][filterDataVal].values.filter((item) => item !== val),
                };
                if ( 
                    updatedValues[type][filterDataVal]?.values?.length === 0 && 
                    updatedValues[type][filterDataVal]?.summedSelections?.length === 0 
                ) { 
                    delete updatedValues[type][filterDataVal]; 
                } 
            } else {
                const type = filterType as DodProductFilterTypes;
                // @ts-ignore temporary fix. need to correct this
                updatedValues[type] = {
                    ...updatedValues[type],
                    values:
                        updatedValues[type].values === 'all'
                            ? []
                            : (updatedValues[type].values as string[]).filter((item) => item !== val),
                };
            }
        }

        onChange?.({value: updatedValues});
        deletePreset(savedSelectionType);
    }

    async function handleDeleteSummedSelection(filter: DodFilterType | string | undefined, index: number) {
        if (filter === undefined) return;
        const {type: filterType, data: filterData} = getFilterType(filter) || {};

        let updatedValues = cloneDeep(value);

        if (filterType === 'markets') {
            updatedValues[filterType].summedSelections!.splice(index, 1);
        } else if (isCharacteristicFilter({type: filterType, data: filterData} as DodFilterType) || isPpgFilter({type: filterType, data: filterData} as DodFilterType)) {
            updatedValues[filterType as DodProductFilterTypes][filterData?.id || filterData].summedSelections!.splice(index, 1);
            if (
              updatedValues[filterType as DodProductFilterTypes][filterData?.id || filterData]?.values?.length === 0 &&
              updatedValues[filterType as DodProductFilterTypes][filterData?.id || filterData]?.summedSelections?.length === 0
            ) {
                delete updatedValues[filterType as DodProductFilterTypes][filterData?.id || filterData];
            }
        } else {
            const summedSelectionFilterType =
                filterType === DodTimePeriodFilterTypes.SUMMED ? DodTimePeriodFilterTypes.TIME_PERIODS : filterType;

                const requiresMarketReset = isMarketResetRequired(
                    {type: filterType, data: filterData} as DodFilterType,
                    value
                );

                let requiresFactReset = false;
                let resetFactFilter = '';

                if (
                    !requiresFactReset &&
                    (filterType === 'upcs' || filterType === 'brands') &&
                    !updatedValues[filterType].values.length &&
                    updatedValues[filterType].summedSelections.length === 1
                ) {
                    requiresFactReset = true;
                    resetFactFilter = filterType;
                }

                if (requiresFactReset) {
                    resetFactSelections?.(resetFactFilter);
                }

                if (requiresMarketReset && !(await confirmMarketReset())) return;
    
                if (requiresMarketReset) {
                    // todo: only remove markets don't match the selected categories
                    updatedValues['markets'] = {
                        values: [],
                        summedSelections: [],
                    };
                    removeMarketPreset();
                } 
                updatedValues[summedSelectionFilterType as string].summedSelections!.splice(index, 1);
        }

        onChange?.({value: updatedValues});
        deletePreset(savedSelectionType);
    }

    async function handleDeleteSummedSelectionValue(filter: DodFilterType | string | undefined, index: number, val: string) {
        if (filter === undefined) return;
        const {type: filterType, data: filterData} = getFilterType(filter) || {};

        let updatedValues = cloneDeep(value);
        let updatedFilterType = filterType as string;

        if (filterType === 'markets') {
            updatedValues[filterType].summedSelections[index].values = updatedValues[filterType].summedSelections[
                index
                ].values.filter((item) => item.name !== val);
        } else if (isCharacteristicFilter({type: filterType, data: filterData} as DodFilterType) || isPpgFilter({type: filterType, data: filterData} as DodFilterType)) {
            const type = filterType as DodProductFilterTypes;
            const filterDataVal = filterData?.id || filterData;
            const summedSelectionValues = updatedValues[type][filterDataVal].summedSelections![index].values;

            updatedValues[type][filterDataVal].summedSelections![index].values = isValidArray(
                summedSelectionValues
            )
                ? (summedSelectionValues as string[]).filter((item) => item !== val)
                : [];

            //* delete node if values array is empty
            if (!updatedValues[type][filterDataVal].summedSelections![index].values.length) {
                updatedValues[type][filterDataVal].summedSelections!.splice(index, 1);
            }
            if (
                updatedValues[type][filterDataVal]?.values?.length === 0 &&
                updatedValues[type][filterDataVal]?.summedSelections?.length === 0
            ) {
                delete updatedValues[type][filterDataVal];
            }
        } else {
            updatedFilterType =
                (filterType === DodTimePeriodFilterTypes.SUMMED ? DodTimePeriodFilterTypes.TIME_PERIODS : filterType) as string;

            const summedSelectionValues = updatedValues[updatedFilterType].summedSelections![index].values;

            if (
                (filterType === 'upcs' || filterType === 'brands') &&
                (val === 'all' ||
                    (!updatedValues[filterType].values.length &&
                        updatedValues[filterType].summedSelections[index].values.length === 1))
            ) {
                resetFactSelections?.(filterType);
            }

            const requiresMarketReset = isMarketResetRequired(
                {type: filterType, data: filterData} as DodFilterType,
                value
            );
            if (requiresMarketReset && !(await confirmMarketReset())) return;

            if (requiresMarketReset) {
                // todo: only remove markets don't match the selected categories
                updatedValues['markets'] = {
                    values: [],
                    summedSelections: [],
                };
                removeMarketPreset();
            }

            updatedValues[updatedFilterType].summedSelections![index].values = isValidArray(summedSelectionValues)
                ? (summedSelectionValues as string[]).filter((item) => item !== val)
                : [];
        }

        // if (!isCharacteristicFilter({type: filterType, data: filterData} as DodFilterType) || !isPpgFilter({type: filterType, data: filterData} as DodFilterType)) {
        //     //* delete node if values array is empty
        //     if (!updatedValues[updatedFilterType][filterData?.id].summedSelections![index].values.length) {
        //         updatedValues[updatedFilterType][filterData?.id].summedSelections!.splice(index, 1);
        //     }
        // }

        onChange?.({value: updatedValues});
        deletePreset(savedSelectionType);
    }

    function handleEditSummedSelection(
        group: string | DodFilterType | undefined,
        index: number
    ) {
        onEditSummedSelection?.(getFilterType(group)!, index);
    }

    function toPreviewNode(group: DodSelectionPreviewNode): DodSelectionPreviewNode {

        const children = group.children ?? group.loadChildren?.(value);
        const filterType = getFilterType(group.filterType);
        // if children are specified ignore the values and summed selections.  The two values should be
        // mutually exclusive.
        const filter = !children ? getFilter(value, filterType) : undefined;
        const node: DodSelectionPreviewNode = {
            key: nanoid(5),
            ...filter,
            ...group,
            expanded: true,
            children: children?.map(toPreviewNode).filter(v => v.hasValues)
        };
        return {
            ...node,
            hasValues: nodeHasValues(node)
        }
    }

    function handleCollapseOrExpand(nodeKey: string): void {
        setCollapsedAndExpandedNodes((prevNodes) => {
            const updatedNodes = {...prevNodes};
            updatedNodes[nodeKey] = !Boolean(updatedNodes[nodeKey]);
            return updatedNodes;
        });
    }

    function flattenFilterPreviewNodes(group: DodSelectionPreviewNode, depth = 1, result: FilterPreviewNode[] = [], index = 0): void {
        // TODO for later: This functionality can be optimized by flattening the array in `toPreviewNode` function, which will eliminate tree conversion process
        const children = group?.children;
        const summedSelections = group?.summedSelections;
        const values = group?.values;
        const isCollapsed = collapsedAndExpandedNodes[group.key as string];

        delete group.children;
        delete group.summedSelections;
        delete group.values;

        result.push({
            key: group?.key,
            label: group?.label as string,
            value: group?.label as string,
            filterType: group?.filterType,
            isGroup: true,
            depth: depth,
            collapsed: isCollapsed,
            ...(group?.filterType === "customTimePeriod" && {
                index: index
            })
        });

        if (isValidArray(children) && !isCollapsed) {
            //* We must pass the calculated index in order to delete the custom time-period value because the time-period's values are in a mixed array data structure.
            let index = 0;
            children.forEach((child: DodSelectionPreviewNode) => {
                const isCustomTimePeriod = child?.filterType === DodTimePeriodFilterTypes.CUSTOM_TIME_PERIOD;
                flattenFilterPreviewNodes(child, depth + 1, result, isCustomTimePeriod ? index : 0);
                if (isCustomTimePeriod) {
                    index++;
                }
            });
        }
        if ((isValidArray(values) || values === "all") && !isCollapsed) {
            result.push(
                ...(values === 'all'
                    ? [
                        {
                            key: nanoid(5),
                            label: 'Includes All',
                            value: "all",
                            filterType: undefined,
                            parentFilterType: group?.filterType,
                            isChild: true,
                            depth: depth + 1,
                        },
                    ]
                    : (skipSorting ? values : values.sort()).map((value: string) => ({
                        key: nanoid(5),
                        label: isTimePeriodFilterTypes(group?.filterType as string) ? timePeriodToString(value, maxDate) : value,
                        value: value,
                        filterType: undefined,
                        parentFilterType: group?.filterType,
                        isChild: true,
                        depth: depth + 1,
                        ...(group?.filterType === "customTimePeriod" && {
                            groupIndex: index
                        })
                    })))
            );
        }
        if (isValidArray(summedSelections) && !isCollapsed) {
            result.push(
                ...summedSelections.flatMap((item, index) => {
                    const isExpanded = collapsedAndExpandedNodes[`summed-${item?.name}-${index}`];
                    return [
                        {
                            key: `summed-${item?.name}-${index}`,
                            label: item?.name,
                            value: item?.name,
                            filterType: undefined,
                            parentFilterType: group?.filterType,
                            isSummedGroup: true,
                            index: index,
                            depth: depth + 1,
                            collapsed: !isExpanded
                        },
                        ...(isExpanded
                            ? item.values === 'all'
                                ? [
                                    {
                                        key: nanoid(5),
                                        label: 'Includes All',
                                        value: 'all',
                                        filterType: undefined,
                                        parentFilterType: group?.filterType,
                                        isSummedSelectionChild: true,
                                        groupIndex: index,
                                        index: 0,
                                        depth: depth + 2,
                                    },
                                ]
                                : item.values.map((itemValue, vIndex) => ({
                                    key: nanoid(5),
                                    label: isTimePeriodFilterTypes(group?.filterType as string)
                                        ? timePeriodToString(itemValue, maxDate)
                                        : itemValue,
                                    value: itemValue,
                                    filterType: undefined,
                                    parentFilterType: group?.filterType,
                                    isSummedSelectionChild: true,
                                    groupIndex: index,
                                    index: vIndex,
                                    depth: depth + 2,
                                }))
                            : []),
                    ]
                })
            );
        }
    }

    const flattenedPreviewNodes = useMemo<FilterPreviewNode[]>(() => getFlattenedPreviewNodes(), [
        nonEmptyGroups,
        collapsedAndExpandedNodes,
    ]);

    const isSaveButtonDisabled = useMemo<boolean>(() => {
        if (savedSelectionType === DodSavedSelectionTypes.PRODUCT) {
            return !flattenedPreviewNodes.filter(
                (node) => node?.filterType === 'categories' || node?.parentFilterType === 'categories'
            ).length;
        } else {
            return !flattenedPreviewNodes?.length;
        }
    }, [flattenedPreviewNodes]);

    return (<ErrorBoundary fallback={<></>} onError={handleError}>
        <DodPanel {...props}
                  className={classnames(baseClassName, className)}
                  title={previewTitle}
                  enableFilter={false}
                  actions={[{
                      key: 'save',
                      disabled: isSaveButtonDisabled,
                      iconType: 'save',
                      disabledTip: 'You have to select at least one value before you can save you selections.',
                      tip: 'Save your current selections to use in future runs.',
                      onClick: handleSaveSelections,
                  }]}>
            <div className={`${baseClassName}__tree`}>
                {isEmpty ? (
                    <div className={`${baseClassName}__empty-state`}>{emptyStateContent}</div>
                ) : (
                    // <div className={`${baseClassName}__autosizer-wrapper`}>
                    <AutoSizer>
                        {({ height, width }) => {
                            return (
                                <FixedSizeList
                                    itemSize={30}
                                    height={height}
                                    itemCount={flattenedPreviewNodes?.length}
                                    width={width}
                                >
                                    {({ style, index }) => {
                                        const node = flattenedPreviewNodes?.[index];
                                        return (
                                            <DodFilterPreviewNode
                                                data={node}
                                                style={style}
                                                onDelete={() =>
                                                    node?.isGroup
                                                        ? handleDeleteGroup(node?.filterType, node?.index)
                                                        : node?.isChild
                                                        ? handleDeleteGroupValue(
                                                              node?.parentFilterType,
                                                              node?.value,
                                                              node?.groupIndex
                                                          )
                                                        : node?.isSummedGroup
                                                        ? handleDeleteSummedSelection(
                                                              node?.parentFilterType,
                                                              node?.index as number
                                                          )
                                                        : handleDeleteSummedSelectionValue(
                                                              node?.parentFilterType,
                                                              node?.groupIndex as number,
                                                              node?.value
                                                          )
                                                }
                                                onEdit={() =>
                                                    handleEditSummedSelection(
                                                        node?.parentFilterType,
                                                        node?.index as number
                                                    )
                                                }
                                                onCollapse={() => handleCollapseOrExpand(node?.key as string)}
                                            />
                                        );
                                    }}
                                </FixedSizeList>
                            );
                        }}
                    </AutoSizer>
                    // </div>
                )}
            </div>
        </DodPanel>
    </ErrorBoundary>);
});

function getFilterType(filterType: DodFilterType | string | undefined): DodFilterType | undefined {
    if (typeof filterType === 'string') {
        return {
            type: filterType
        }
    }
    return filterType;
}

function getFilter(filters: DodFilters, filterType: DodFilterType | undefined): DodFilter | undefined {

    if (!filterType) return;

    const {type, data: subType} = filterType;
    if (subType) {
        return filters[type][subType];
    }

    return filters[type];
}

function getGroupValueCount(filters: DodFilters, filterType: DodFilterType | undefined): number {

    return getFilter(filters, filterType)?.values.length ?? 0;
}

function nodeHasValues(node: DodSelectionPreviewNode): boolean {

    return Boolean(node.children?.length) || Boolean(node.values === 'all' || (Array.isArray(node.values) && node.values?.length) || node.summedSelections?.length)
}

function handleError(err) {
    console.error(err);
}

export default DodFilterPreview;
