import React, { useState, useEffect, useMemo, useImperativeHandle, forwardRef, ReactNode } from "react";
import classnames from 'classnames';
import './AboutYouContent.scss';
import { ByzzerButton, ByzzerTextInput, WithValue } from "@byzzer/ui-components";
import { useUser } from "@/contexts/UserContext";
import { PersonaDetails } from "@/types/ApiTypes";
import MarkdownEditor from "@uiw/react-markdown-editor";
import { TrackClick } from "@/analytics";

const baseClassName = 'byz-onboarding-about-you';

export const OTHER_PERSONA_CODE = 'other';

type PersonaCardProps = {
    persona: PersonaDetails;
    selected?: boolean;
    onClick?: (code: string) => void;
}

function PersonaCard({
    persona, 
    selected, 
    onClick
}: PersonaCardProps) {
    const {
        title,
        description,
        iconUrl,
        code
    } = persona;
    function handleClick() {
        onClick?.(code);
    }
    return (
        <div 
            className={classnames(`${baseClassName}__persona-card`, {
                [`${baseClassName}__persona-card--selected`]: selected
            })}
            onClick={handleClick}
        >
            <img className={classnames(`${baseClassName}__persona-card__thumbnail`)} src={iconUrl!}/> {/* switch to pure css */}
            <div className={classnames(`${baseClassName}__persona-card__title`)}>
                <MarkdownEditor.Markdown source={title}/>
            </div>
            <div className={classnames(`${baseClassName}__persona-card__description`)}>
                <MarkdownEditor.Markdown source={description}/>
            </div>
        </div>
    )
}

function PersonaCards({selectedPersonas, onClick}: {selectedPersonas: string[], onClick: (personaCode: string) => void}) {
    const { personas = [], company } = useUser(); // may move personas to its own api call
    function handleClick(personaCode: string) {
        onClick(personaCode);
    }
    return <>
        {[...personas]
            .sort((a, b) => a.personaOrder - b.personaOrder)
            .filter(persona => persona?.isApplicableToAllAccessLevels || persona?.applicableAccessLevels?.includes(company?.accessLevel!))
            .map(persona => (
                <PersonaCard
                    key={persona.code} 
                    persona={persona} 
                    onClick={handleClick} 
                    selected={selectedPersonas?.includes(persona.code)}
                />
            ))
        }
    </>
};

export type AboutYouContentProps = {
    className?: string;
    otherPersonaInputPlaceholder?: string;
    otherActions?: ReactNode;
    onSubmit?: (selections: PersonaSelections) => Promise<void>;
    onSkip?: () => Promise<void>;
    onChange?: (event: ByzzerChangeEvent<PersonaSelections>) => void;
    includeSubmit?: boolean;
    includeSkip?: boolean;
    submitLabel?: string;
    skipLabel?: string;
    value?: PersonaSelections;
}


export type PersonaSelections = {
    selectedPersonas: string[];
    otherPersonaDesc: string;
}

export const defaultPersonaSelections: PersonaSelections = {
    selectedPersonas: [],
    otherPersonaDesc: ''
}

export const AboutYouContent = forwardRef<WithValue<PersonaSelections>, AboutYouContentProps>(({
    className,
    otherPersonaInputPlaceholder = 'Type in the role that describes your duties the best',
    otherActions,
    includeSubmit,
    includeSkip,
    onSubmit,
    onSkip,
    onChange,
    submitLabel = 'Submit',
    skipLabel = 'Skip',
    value,
    // ...props
}, ref) => {
    const defaultValue = value?.otherPersonaDesc !== undefined && value?.selectedPersonas !== undefined ? value! : defaultPersonaSelections;
    const [{selectedPersonas, otherPersonaDesc}, setInternalValue] = useState(defaultValue);
    const [busy, setBusy] = useState(false);
    const {company, user} = useUser();

    useEffect(() => {
        if (value?.otherPersonaDesc !== undefined && value?.selectedPersonas !== undefined) {
           setInternalValue(value);
        }
    }, [value?.otherPersonaDesc, value?.selectedPersonas]);

    useImperativeHandle(
        ref,
        () => ({
            value: {
                otherPersonaDesc,
                selectedPersonas,
            }
        }), 
        [otherPersonaDesc, selectedPersonas]
    );

    function handleOtherPersonaInput({value: inputValue = ''}: ByzzerChangeEvent<string>) {
        let currPersonas = selectedPersonas;
        let newPersonas = [...currPersonas];
        if (inputValue && !currPersonas.includes(OTHER_PERSONA_CODE)) {
            newPersonas = [...currPersonas, OTHER_PERSONA_CODE]
        } else if (!inputValue) {
            newPersonas = currPersonas.filter(code => code !== OTHER_PERSONA_CODE)
        }
        if (onChange) {
            onChange({
                value: {
                    selectedPersonas: newPersonas,
                    otherPersonaDesc: inputValue
                }
            });
        } else {
            setInternalValue(curr => ({
                selectedPersonas: newPersonas,
                otherPersonaDesc: inputValue
            }));
        }
    }

    function handlePersonaCardClick(clickedPersonaCode: string) {
        let newPersonas: typeof selectedPersonas;
        if (selectedPersonas.includes(clickedPersonaCode)) {
            newPersonas = selectedPersonas.filter(code => code !== clickedPersonaCode)
        } else {
            newPersonas = [...selectedPersonas, clickedPersonaCode];
        }
        if (onChange) {
            onChange({
                value: {
                    ...value!,
                    selectedPersonas: newPersonas
                }
            });
        } else {
            setInternalValue(curr => ({
                ...curr, 
                selectedPersonas: newPersonas
            }));
        }
    }

    async function handleSubmitClick() {
        setBusy(true);
        try {
            await onSubmit?.({
                otherPersonaDesc: otherPersonaDesc.trim(),
                selectedPersonas
            });
        } catch (error) {
            // tbd
            throw error;
        } finally {
            setBusy(false);
        }
    };

    async function handleSkipClick() {
        setBusy(true);
        try {
            await onSkip?.();
        } catch (error) {
            // tbd
            throw error;
        } finally {
            setBusy(false);
        }
    };

    const showFooter = Boolean(otherActions ?? includeSubmit ?? includeSkip);

    const disableSubmit = busy || (!selectedPersonas?.length && !otherPersonaDesc?.length);

    return (
        <div className={classnames(`${baseClassName}`, className)}>
            <main className={classnames(`${baseClassName}__content`)}>
                <h1 id={'persona_which_role_fits_you'}>Which role(s) fits you best?</h1>
                <div className={`${baseClassName}__persona-cards`}>
                    <PersonaCards
                        onClick={handlePersonaCardClick}
                        selectedPersonas={selectedPersonas}
                    />
                </div>
                <div className={`${baseClassName}__persona-other`}>
                    <span>Other:</span>
                    <ByzzerTextInput 
                        onChange={handleOtherPersonaInput} 
                        value={otherPersonaDesc} 
                        name={'other_persona'} 
                        placeholder={otherPersonaInputPlaceholder} 
                    />
                </div>
            </main>
            {showFooter && (
                <footer className={classnames(`${baseClassName}__footer`)}>
                    <div className={classnames(`${baseClassName}__actions`)}>
                        {otherActions}
                        {includeSkip && (
                            <TrackClick
                                name={'persona_popup_skipped'}
                                data={{
                                    companyName: company?.displayName,
                                    authorizedUserId: user?.authorizedUserId,
                                    userEmail: user?.email,
                                    previousSkippedPersonasDtm: user?.metadata?.skippedPersonasDtm
                                }}
                            >
                                <ByzzerButton
                                    label={skipLabel}
                                    type={'negative'}
                                    onClick={handleSkipClick}
                                    disabled={busy}
                                />
                            </TrackClick>
                        )}
                        {includeSubmit && (
                            <ByzzerButton
                                label={submitLabel}
                                onClick={handleSubmitClick}
                                disabled={disableSubmit}
                            />
                        )}
                    </div>
                </footer>
            )}
        </div>
    );

});

export default AboutYouContent;

AboutYouContent.displayName = 'AboutYouContent';