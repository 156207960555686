import './DodMarketFilterTypePicker.scss';
import React, { useEffect, useMemo, useState } from 'react';
import {
    DodFilterTypeOptionGroup,
    DodFilterTypePicker,
    DodFilterTypePickerProps
} from '@/components/DodConfigEditor/common';
import { DodFilters } from '@/types/DodRun';
import {DodFilterType, DodMarketFilterType, DodProductFilterType} from '@/components/DodConfigEditor/types';
import { ByzzerChangeEventHandler, ByzzerSelectOption } from '@byzzer/ui-components';
import classnames from 'classnames';
import { SAVED_MARKET_INFO } from '@/components/DodConfigEditor/builders/DodMarketFilterBuilder/MarketConstants';
import { DodPreset, DodPresetSummary } from '@/types/ApiTypes';
import { Tabs } from 'antd';
import DodMarketAdvancedSearch from '../DodMarketAdvancedSearch/DodMarketAdvancedSearch';
import { AdvancedSearchFilters, MarketTab } from '../DodMarketFilterBuilder';
import { useUser } from '@/contexts/UserContext';
import { useMarketService } from '@/services/market.service';
import {alert as byzzerAlert} from '@/components/form'
const baseClassName = 'dod-market-filter-type-picker';

export type DodMarketFilterTypeValue = {
    type: DodMarketFilterType;
    value?: string;
};

export type DodMarketFilterTypePickerProps = {
    name?: string;
    value: DodMarketFilterType;
    onChange: ByzzerChangeEventHandler<DodMarketFilterType>;
    filters: DodFilters;
    tipDelay?: number | [number | null, number | null];
    onPresetSelect: ByzzerChangeEventHandler<DodPreset>;
    advancedSearchFilters: AdvancedSearchFilters;
    onAdvancedSearchFiltersChange: ByzzerChangeEventHandler<AdvancedSearchFilters>;
    activeMarketFolderTab: MarketTab;
    onTabChange: (activeKey: string) => void;
    accessibleMarketFolders: any
} & Partial<Omit<DodFilterTypePickerProps<DodFilters, DodMarketFilterType>, 'initialOptionGroups'>>;

export function DodMarketFilterTypePicker({
    className,
    onChange,
    filters,
    advancedSearchFilters,
    onAdvancedSearchFiltersChange,
    activeMarketFolderTab,
    onTabChange,
    accessibleMarketFolders,
    ...props
}: DodMarketFilterTypePickerProps) {
    const { features } = useUser();
    const { getCachedMarketNodesByKeys } = useMarketService();
    const [optionGroups] = useState<DodFilterTypeOptionGroup<DodFilters, DodMarketFilterType>[]>([
        {
            label: 'Market Type',
            options: features?.enableLimitedMarketSubscription ? accessibleMarketFolders : [
            {
                key: 'all',
                label: 'All',
                value: 'all',
            },
            {
                key: 'channels',
                label: 'Channels',
                value: 'channels',
            },
            {
                key: 'accounts',
                label: 'Accounts',
                value: 'accounts',
            },
            {
                key: 'stateLines',
                label: 'Statelines',
                value: 'stateLines',
            },
            {
                key: 'majorMarkets',
                label: 'Major Markets',
                value: 'majorMarkets',
            },
            {
                key: 'regions',
                label: 'Regions',
                    value: 'regions',
            },
            {
                key: 'divisions',
                label: 'Divisions',
                value: 'divisions',
            },
            ],
            },
        ]);

    const { TabPane } = Tabs;

    function handleChange(e) {
        onChange(e);
    }

    const hideAdvancedSearch = features?.enableLimitedMarketSubscription;

    //BYZ-12114:show popup if saved markets have no access to category
    function handleValidateSavedMarket(savedMarket: DodPreset) {
        const savedMarketSelections = savedMarket.values.markets;
        if (!savedMarketSelections) {
            // may be we can show some alert since there are no selections in saved markets
            return false; // invalid since applying will just clear all selections
        }
        const hasUnapprovedMarkets = getCachedMarketNodesByKeys(
            savedMarketSelections.values.map((item) => item.key),
            {
                isDodMarket: true,
                categories: filters.categories.values as string[],
                isSummedMode: filters.categories.summedSelections.length ? true : false,
                summedCategories: filters.categories.summedSelections as any,
                nonSummedCategories: filters.categories.values as string[],
            }
        ).some((item) => item.hasNoApproval === true);
        const hasUnapprovedSummedMarkets = getCachedMarketNodesByKeys(
            savedMarketSelections.summedSelections.flatMap(({ values }) => values).map((item) => item.key),
            {
                isDodMarket: true,
                categories: filters.categories.values as string[],
                isSummedMode: filters.categories.summedSelections.length ? true : false,
                summedCategories: filters.categories.summedSelections as any,
                nonSummedCategories: filters.categories.values as string[],
            }
        ).some((item) => item.hasNoApproval === true);

        const isValid = !hasUnapprovedMarkets && !hasUnapprovedSummedMarkets;
        if (!isValid) {
            byzzerAlert({
                content: (
                    <div>
                        This market list contains market(s) that do not have coverage in your selected category(s).
                    </div>
                ),
            });
        }
        return isValid;
    }

    return (
        <div className={classnames(baseClassName, className)}>
            <Tabs defaultActiveKey={activeMarketFolderTab} onChange={onTabChange} className={`${baseClassName}__content-wrapper`} >
                <TabPane tab="Market Type" key="markets">
                    <DodFilterTypePicker
                        className={`${baseClassName}__market-type-picker`}
                        filters={filters}
                        initialOptionGroups={optionGroups}
                        onChange={handleChange}
                        filterPlaceholder={'Search Market Folders'}
                        presetType={'market'}
                        presetGroupLabel={'My Saved Markets'}
                        presetGroupTip={SAVED_MARKET_INFO}
                        {...props}
                    />
                </TabPane>
                {!hideAdvancedSearch && (
                    <TabPane tab="Advanced Search" key="search">
                        <DodMarketAdvancedSearch value={advancedSearchFilters} onChange={onAdvancedSearchFiltersChange} />
                    </TabPane>
                  )}
            </Tabs>
        </div>
    );
}

export default DodMarketFilterTypePicker;
